import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductionService } from '../../../../../service/production.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { DatePipe } from '@angular/common';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';


import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-inter-branch-stock-transfer',
  templateUrl: './inter-branch-stock-transfer.component.html',
  styleUrls: ['./inter-branch-stock-transfer.component.css']
})


export class InterBranchStockTransferComponent implements OnInit {


  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;


  @ViewChild('fieldName1', { static: false }) fieldName1: ElementRef;
  @ViewChild('fieldName2', { static: false }) fieldName2: ElementRef;

  @ViewChild('fieldName3', { static: false }) fieldName3: ElementRef;
  @ViewChild('fieldName4', { static: false }) fieldName4: ElementRef;
  @ViewChild('prdctSearch', { static: false }) prdctSearch: NgSelectComponent;
  @ViewChild('prdctSearch2', { static: false }) prdctSearch2: NgSelectComponent;
  branch_id: any;
  prodctnId: any;
  request_curpage = 1;
  request_lastpage = 1;
  prdn_date = new Date();
  step3 = false;
  step2 = false;
  step1 = false;
  searchExisting = true;
  calculationDisplay = true;
  netcost: any;
  prodform_id: any;
  ttlCommAmount: any;
  output_qty: number;
  prdAll = [];
  filterProdAll = [];
  selProdIds: any = {};
  formula_all: any;
  prdIngAll = [];
  filterIngrdProd = [];
  selIngProdIds: any = {};
  previewData = [];
  staffs = [];
  filterStaffs = [];
  selStaffs: any = {};

  commAll = 0;
  comsnCommAmnt = 0;
  resultobj: any = {};
  stocktr_amount = 0;
  branchtran_request_id =0;
  prodFormula = {
    prdn_date: new Date(),
    productnId: 0,
    productnDate: 0,
    formula_id: '',
    formula_name: '',
    productOut: [],
    productIngd: [],
    isProdCommsn: false,
    // 0 for all and 1 for individual productIngd: [{
    staffs: [],
    insp_staff: null,
    ttlComm: 0,
    ttlPurchase: 0,
    miscExp: 0,
  };
  formula_list = [];
  info = [];
  isSubmitInProg = false;
  pageLoadingImg = false;

  curpage = 1;
  lastpage = 1;
  perpage = 5;
  totalItem = 0;
  godown_all = [];
  // rate_all = [];
  curForm = 'Create';

  rate_all = [
    { "type_id": 1, 'name': 'Sales Rate'  },
    { "type_id": 2, 'name': 'Purchase Rate'  },
    { "type_id": 3, 'name': 'Custom' },
    { "type_id": 4, 'name': 'Last Transfer Rate' },
    { "type_id": 5, 'name': 'Average Cost' }

  ];
  unit_qty: any;
  display_qty: any;
  product_summary: any;
  purch_no: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  vantran_godown_id: any;
  vantran_van_id: any;
  brcode: any;
  prd_stock_statusChecked: any;
  prd_stock_status: any;


  tran_purch_price: any;
  tran_purch_tax_rate: any;
  transub_qty: number;
  tran_purch_amount: number;
  transub_unit_id: any;


  prd_id: any;
  tran_id: number;
  vantran_purch_price: any;
  vantran_purch_tax_rate: any;
  prd_barcode: String;
  vantransub_qty: any;
  vantran_purch_amount: number;
  vantransub_unit_id: any;
  default_unit: any;
  total_qty = 0;
  NgForm: any;
  total_amount: any;
  sel_gd_id = 0;
  tranArray: any;
  sl_no = 0;
  prd_units = [];
  van_all = [];
  prd_all = [];
  selectedItems = [];
  resDup: any;
  printError = [];
  vantran_date: any;
  prd_name_search: any;
  alias: any;
  vanlines: any[];
  VanLine: any[];
  preview_tran: any[];
  changeBox: boolean;
  pgstart: boolean;
  pgend: boolean;
  slnum: number;
  box = 0;
  preview_id = 0;
  preview_date: any[];
  preview_gd: any[];
  preview_van: any[];
  preview_prize: any[];
  preview_items: any[];
  base_rate: number;
  transferdate: any;

  accepted_list = [];
  rejected_list = [];
  previewDataitems = [];
  sale_rate: any;
  sale_tax_rate:any;
  salestatus: any;
  type_id = 1;

  gdid: number;
  errObjArr = {
    tandateErr: null, tanvanErr: null,
    tangdErr: null
  };
  errObjTran = {
    fromErr: null, toErr: null,
    sameErr: null
  };
  rate_type = [
    { id: 1, name: 'Rate1' },
    { id: 2, name: 'Rate2' },
  ];
  sel_rate_type = 1;
  rate_status: any;
  rate_status_type: any;

  searchStkBatch: any;
  acurpage: any;
  alastpage: any;
  apgend: boolean;
  apgstart: boolean;
  afrom: any;
  rpgstart: boolean;
  rpgend: boolean;
  accpgstart: boolean;
  accpgend: boolean;
  from: any;
  rfrom: any;
  balance: number;
  batch_name: any;
  gd_id: any;
  godowns: any;
  branchto: string | number | string[];
  up: any;
  showrate: any=false;
  userdefltGwn: any;
  gd_id_edit: any;
  avgPurch_rate: any;
  userEncpType: any;
  selctedProd: any;
  settings :any;
  add_perc:any;
  net_rate:any;
  add_perc_edit:any;
  net_rate_edit:any;
  purch_id:any;
  purchLoadingImg = false;
  voidInprogress=<any>{};
  country_dec: string;
  sales_settings: any;
  search_type: any;
  usr_hide_purch_cost: any;
  branchStocksTotal: number;
  branchStocks: any;
  tax_rate: any;
  cur_lang: string;
  base_url: any;
  img_url: any;
  cmpny: any;
  header_type: any;
  logoImg: any;
  logoImgUrl: any;
  generalSearch_type: any;
  g_settings: any;
  below_purch_enable: any=1;
  purchase_rate: number;
  editData = false;
  loading: boolean;
  usr_block_pre_date_select: any;
  minDate: Date;
  branch_default_search: any;
  isEdit1 =false;
  isEdit = false;
  request_list =[];
  request_from: any;
  request_slnum: number;
  previewReqData =[];
  pendingTransferRequest: any;
  currentBranch: any;
  stockItems= [];
  perc=false;

  constructor(private productionService: ProductionService, private datePipe: DatePipe, private apiService: ApiService,
    private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
    this.getGeneralSetting();
    this.listTransferRequests();
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.add_perc=0;
    this.net_rate=0;
    this.add_perc_edit=0;
    this.net_rate_edit=0;
     this.getTrasferSetting();
    this.getSalesSetting();
    this.pendingTransferRequests();
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    this.up = this.coreService.getUserPrivilage();
    this.userEncpType = this.coreService.getUserData('usr_enc_rate_code');

    this.prodform_id = '';
    this.prdn_date = new Date();
    this.type_id = 1;
    
    this.listGodowns();
    this.listTransfers();
    this.listAccepted();
    this.listRejected();
    this.listAllBranches();
    // this.setRateTypes();
    this.setProdId();

    $('#branchid').css("border", "1px solid #dedede");
    $('#branch_from').css("border", "1px solid #dedede");
    console.log('oninit',  this.showrate);
    this.showrate = localStorage.showrate&&localStorage.showrate=='1' ? 1 : 0;
   
    console.log('oninittt',  this.showrate);
    this.userdefltGwn = this.coreService.getUserData('usr_default_godown_id');
    this.gd_id = Number(this.userdefltGwn);
    this.gd_id_edit = Number(this.userdefltGwn);
    
    if (this.gd_id > 0) {

    }
    else {
      this.gd_id = 0;
    }
   

    this.country_dec=this.coreService.setDecimalLength();

    this.getHeaderImage();
    this.cmpny = this.coreService.getUserData('cmpny');

    console.log("User dataaaa");
    console.log(this.coreService.getUserData('branch_id'));
    this.currentBranch =this.coreService.getUserData('branch_id');
    this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    if(this.usr_block_pre_date_select){
      this.minDate= new Date();
    }else{
      this.minDate= new Date(this.coreService.getUserData("branch_open_date"));
    }

  }

  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.commAll = 1;
      
    }else{
      this.commAll =2;
      
    }
  }

  ngOnDestroy() {
    $('.close').click();

  }

  getTrasferSetting() {

    this.apiService.getTrasferSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.type_id = this.settings.ts_rate_type;
        this.tax_rate = this.settings.ts_tax_rate;
        this.below_purch_enable = this.settings.ts_grant_below_purch_rate;
        this.branch_default_search = this.settings.ts_default_search; 
        this.add_perc= this.settings.ts_default_add_perc;
      } else { 
        this.type_id = 1;
        this.tax_rate = 0;
      }
    });

  }


  listAccepted() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '1');
    this.apiService.listSendTransfers(searchval, 1).subscribe((res) => {

      this.accepted_list = res['data'];


      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;
    });
  }



  acceptpageNext(status, pageno: any) {


    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listSendTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }



      this.pageLoadingImg = false;
      this.acurpage = res['current_page'];
      this.alastpage = res['last_page'];
      this.afrom = res['from'];

      this.apgEnd(this.acurpage, this.alastpage);
      this.apgStart(this.acurpage);
      this.slnum = 0;


    });
  }

  apgEnd(curr, end) {
    if (curr == end)
      this.apgend = true;
    else
      this.apgend = false;

  }

  apgStart(curr) {
    if (curr == 1)
      this.apgstart = true;
    else
      this.apgstart = false;



  }

  // reject List end


  listRejected() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '2');
    this.apiService.listSendTransfers(searchval, 1).subscribe((res) => {

      this.rejected_list = res['data'];


      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];

      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;
    });
  }



  rejectpageNext(status, pageno: any) {

    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listSendTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }


      this.pageLoadingImg = false;
      this.rcurpage = res['current_page'];
      this.rlastpage = res['last_page'];
      this.rfrom = res['from'];
      this.rpgEnd(this.rcurpage, this.rlastpage);
      this.rpgStart(this.rcurpage);
      this.slnum = 0;


    });
  }

  rpgEnd(curr, end) {
    if (curr == end)
      this.rpgend = true;
    else
      this.rpgend = false;

  }

  rpgStart(curr) {
    if (curr == 1)
      this.rpgstart = true;
    else
      this.rpgstart = false;

  }

  // reject List end


  rlastpage(rcurpage: (rcurpage: any) => void, rlastpage: any) {
    throw new Error("Method not implemented.");
  }
  rcurpage(rcurpage: any) {
    throw new Error("Method not implemented.");
  }

  setProdId() {
    let searchval = new FormData();
    this.apiService.gebranchTranId(searchval).subscribe((res) => {
      this.prodctnId = res['data']['tran_id'];
    });
  }
  listGodowns() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godowns = res['data'];

    });

  }

  setRateTypes() {
    this.rate_all.push({ "type_id": 1, 'name': 'MRP' },
      { "type_id": 2, 'name': 'Purchase Rate' },
      { "type_id": 3, 'name': 'Custom' },
      { "type_id": 4, 'name': 'Last Transfer Rate' },
      { "type_id": 5, 'name': 'Average Cost' }

    );



  }
  listAllBranches() {
    let searchval = new FormData();

    this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this.godown_all = res['data'];



    });

  }

  searchForm(search: string) {

    let searchval = new FormData();
    searchval.append("gd_name", search);
    this.apiService.getAllBranchesforTransfer().subscribe((res) => {
      this.godown_all = res['data'];



    });


  }


  productionPreview(prdn_id,edit_pending) {
    this.perc=true;

    let searchval = new FormData();
    searchval.append("prdn_id", prdn_id);
    this.apiService.transferPreview(searchval).subscribe((res) => {
      this.previewData = res;
      // this.branch_from = res.from_branch;
       
      console.log("fffff");
      console.log(res.items[0]);
      // if(res.stocktr_draft_code){
      //   this.tax_rate = 0;
      // }
      if(res.items[0].stocktrsub_tax_rate_status == 0){
        this.tax_rate = 0;
      }
      else{
        this.tax_rate = 1;
      }
      // else{
      //   console.log("hhh");
      // }
      
      this.previewDataitems = res.items;
      console.log('this.previewDataitemsgg',this.previewDataitems);
      
      // res.items.forEach(element => {

      //   this.previewDataitems['tax_rate'] = element.stocktrsub_tax_rate_status;
      //   this.previewDataitems['stocktrsub_tax_rate'] = element.stocktrsub_include_tax_rate;
      //   this.previewDataitems['add_perc'] = element.stocktrsub_add_perc;
      // });
      


      this.stocktr_amount = res.stocktr_amount;
      $('#brtran-btn').show();


      this.transferdate = this.datePipe.transform(res.stocktr_date, 'dd/MM/yyyy');

      // this.transferdate = new Date(this.transferdate); 

      var to = this.transferdate.split("/");
      this.transferdate = new Date(to[2], to[1] - 1, to[0]);
      this.gd_id_edit = Number(this.userdefltGwn);
      // only to cal in cas eof edit
      // if(edit_pending){
        // this.findTotalSum2();
      // }
      // this.findTotalSum2();

      // this.transferdate = new Date(res.stocktr_date);


    });

  }

 


  selectBatch(Batchcode, index) {


    this.prodFormula.productOut[index]['batch_code'] = Batchcode.sb_batch_code;
    this.prodFormula.productOut[index]['exp_date'] = new Date(Batchcode.sb_expiry_date);
    this.prodFormula.productOut[index]['manf_date'] = new Date(Batchcode.sb_manufacture_date);
    this.prodFormula.productOut[index]['gd_id'] = 0;
  }



  changeUnit(qty, index) {
   
    this.prodFormula.productIngd[index]['qty'] *= qty;
  }
  // getInfo(pageNo) {
  //   this.pageLoadingImg = true;
  //   this.productionService.getAllProductions(pageNo, this.perpage).subscribe((res) => {
  //     this.formula_list = res.data.data;
  //     this.pageLoadingImg = false;
  //     this.curpage = res.data.current_page;
  //     this.lastpage = res.data.last_page;
  //     this.totalItem = res.data.total;
  //   });
  // }




  // Pending List 


  listTransfers() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    searchval.append("status", '0');
    this.apiService.listSendTransfers(searchval, 1).subscribe((res) => {

      this.formula_list = res['data'];


      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;
    });
  }



  pageNext(status, pageno: any) {


    let searchval = new FormData();
    searchval.append("status", status);
    this.apiService.listSendTransfers(searchval, pageno).subscribe((res) => {

      if (status == 1) {
        this.accepted_list = res['data'];
      }
      if (status == 2) {
        this.rejected_list = res['data'];
      }

      if (status == 0) {
        this.formula_list = res['data'];
      }



      this.pageLoadingImg = false;
      this.curpage = res['current_page'];
      this.from = res['from'];
      this.lastpage = res['last_page'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);
      this.slnum = 0;


    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;

  }


  // Pending End 



  filterProducts() {
    this.filterProdAll = this.prdAll.filter(e => {
      if (Object.values(this.selProdIds).indexOf(e.prd_id) < 0) {
        return true;
      }
    });
  }



  deleteItem(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.selectedItems.length;
      var type = data.sl_no;



      var i;
      for (i = this.selectedItems.length - 1; i >= 0; --i) {
        if (this.selectedItems[i].sl_no == type) {
          this.selectedItems.splice(i, 1);
        }
      }

      

      for (i = this.stockItems.length - 1; i >= 0; --i) {
        if (this.stockItems[i].sl_no == type) {
          this.stockItems.splice(i, 1);
        }
      }

      console.log("remaining");
      console.log(this.stockItems);
      if (this.selectedItems.length == 0)
        $('#gdtran-btn').hide();
      this.findTotalSum();
    }
  }

  editItem(data, i) {
    console.log('suii',data);
    console.log('data',data);
    
    $('.edit_row').removeClass('sel_row');
    $('#myrow' + i).addClass('sel_row');
    if(this.selectedItems.length >0)
    {
     
      console.log('data11');
      console.log(data);
      
  
        const searchval = new FormData();
        searchval.append('prd_id', data.vantransub_prod_id);
        searchval.append('gd_id', data.gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {
          
       

          this.selctedProd = res['data'];

          this.prd_units = this.selctedProd[0].prd_units;
  
  
          this.selctedProd[0].prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.vantransub_unit_id) {

  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
       
      
            };
          });
    
         
        });
  
        
        data.salesub_prd_id =data.vantransub_prod_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {

  
          this.sl_no = data.sl_no;
          this.prd_id = res.data.product;
          this.avgPurch_rate = data.vantransub_purch_rate;
          // this.sale_rate = this.prd_id.bs_srate;
          //  this.base_rate = this.prd_id.bs_prate;
          this.vantransub_qty = data.vantransub_qty / data.unit_base;
         // this.vantran_purch_price =data.purchase_rate * data.unit_base;
          this.vantran_purch_price =data.rate;
          this.add_perc = data.add_perc;
          if(this.add_perc>0){
            this.vantran_purch_tax_rate =( Number(data.rate)+(Number(data.rate*data.prd_tax)/100))*data.unit_base;

          }else{
            this.vantran_purch_tax_rate =data.purchase_tax_rate*data.unit_base;

          }
         
         
          this.net_rate =data.net_rate;
          this.type_id =data.type_id;
          this.gd_id =data.gd_id;
       
  
        });
  
        this.isEdit =true;
        
       
    }

    else{
    
    
      
  
        const searchval = new FormData();
        searchval.append('prd_id', data.prd_id);
        searchval.append('gd_id', data.extstktrsub_gd_id);
        this.apiService.getExTransProdDetails(searchval).subscribe((res) => {

        

          this.selctedProd = res['data'][0];
       
        
  
          this.prd_units = this.selctedProd.prd_units;
  
  
  
          this.selctedProd.prd_units.forEach((value, i) => {
            if (value.sur_unit_id == data.vantransub_unit_id) {
      
             
  
             this.default_unit = {
              "unit_code": value.unit_code, "unit_name": value.unit_name,
              "sur_unit_id": value.sur_unit_id, "unit_base_qty": value.unit_base_qty,

        "sale_rate": value.sale_rate, "purch_rate": value.purch_rate,
        "last_rate": value.last_rate,
        "avg_cost": value.avg_cost,
              
      
            };
  
          
      
            };
          });
    
         
        });
  
        
        data.salesub_prd_id =data.prd_id;
        this.apiService.geteditSalesItem(data).subscribe((res: any) => {
        
         
          this.sl_no = data.sl_no;
          this.prd_id = res.data.product;
          this.transub_qty = data.box;
      
          this.add_perc_edit = data.stocktrsub_add_perc;
          this.tran_purch_price =data.rate*data.unit_code_base_qty;
          if(this.add_perc_edit>0){
            // this.tran_purch_price =(data.rate*data.unit_code_base_qty) / (1 + data.stocktrsub_add_perc / 100);
            this.tran_purch_price =(data.rate) / (1 + data.stocktrsub_add_perc / 100);
            
            // this.tran_purch_tax_rate =Number(Number(this.tran_purch_price/data.unit_code_base_qty)+(Number(this.tran_purch_price/data.unit_code_base_qty)*Number(data.prd_tax)/100))*Number(data.unit_code_base_qty);
            this.tran_purch_tax_rate =Number(Number(this.tran_purch_price)+(Number(this.tran_purch_price)*Number(data.prd_tax)/100));
            
           

          }else{
            this.tran_purch_tax_rate =data.purchase_tax_rate*data.unit_code_base_qty;

          }
          
         
         
          this.net_rate_edit = data.stocktrsub_tax_rate_status === 1? data.stocktrsub_include_tax_rate*data.unit_code_base_qty: data.stocktrsub_rate*data.unit_code_base_qty;
          
          this.type_id =data.type_id;
          this.gd_id_edit =data.stocktrsub_gd_id;
       
  
        });
      
        this.isEdit1 =true;
      
    }

    

    // if (data) {
     

    // }
  }
  editSaleItem(prd_data, prd_barcode, vantransub_unit_id)
  {
    console.log('editsaleitem',this.selectedItems);
    console.log('prd_data',prd_data);
    console.log('vantransub_unit_id',vantransub_unit_id);
    
    
    $('.edit_row').removeClass('sel_row');
   



    // alert(prd_data.prd_barcode);
    // alert(vantransub_unit_id.unit_base_qty);

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }



    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {


      if(this.add_perc){
        let nr = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
        this.vantran_purch_price = nr;
      }

      this.box = this.vantransub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);

      }


      //this.sl_no = (this.selectedItems.length) + 1;


      this.vantransub_qty = Number(this.vantransub_qty);
      this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.vantran_purch_price,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }
         

        
        else {
          // this.selectedItems.push({
          //   "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
          //   "prd_id": prd_data.prd_id,
          //   "stocktrsub_prd_id": prd_data.prd_id,

          //   "prd_name": prd_data.prd_name,
          //   "vantransub_qty": this.vantransub_qty,
          //   "stocktrsub_qty": this.vantransub_qty,
          //   "box": this.box,
          //   "gd_id": this.gd_id,
          //   "purchase_rate": this.vantran_purch_price,
          //   "purchase_amount": this.vantran_purch_amount,
          //   "vantransub_prod_id": prd_data.prd_id,
          //   "vantransub_stock_id": prd_data.bs_stock_id,
          //   "vantransub_branch_stock_id": prd_data.branch_stock_id,
          //   "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
          //   "vantransub_purch_rate": this.avgPurch_rate,
          //   "unit_name": vantransub_unit_id.unit_name,
          //   "unit_base": vantransub_unit_id.unit_base_qty,
          //   "unit_code": prd_data.base_unit_code,
          //   "base_unit_name": prd_data.base_unit_name,
          //   "display_qty": this.display_qty,
          //   "item_unit_code": vantransub_unit_id.unit_code,
          //   "prd_stock_status": prd_data.prd_stock_status,
          //   "add_perc" : this.add_perc,
          //   "net_rate" :this.net_rate,
          //   "rate" : $('#vantran_purch_price').val()

          // });


         
      this.selectedItems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_qty = this.vantransub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).stocktrsub_qty = this.vantransub_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).box = this.box;
      this.selectedItems.find(x => x.sl_no === this.sl_no).gd_id = this.gd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_tax_rate =Number(this.net_rate)/Number(vantransub_unit_id.unit_base_qty);
      console.log('this.net_rate',this.net_rate);
      console.log('prd_data.unit_base',vantransub_unit_id.unit_base_qty);
      this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_rate = this.vantran_purch_price;
      this.selectedItems.find(x => x.sl_no === this.sl_no).purchase_amount = this.vantran_purch_amount;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.bs_stock_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.branch_stock_id;

      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = vantransub_unit_id.sur_unit_id;
      this.selectedItems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = this.avgPurch_rate;
    
      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_name = vantransub_unit_id.unit_name;

      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_base = vantransub_unit_id.unit_base_qty;
     // this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = prd_data.base_unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).unit_code = vantransub_unit_id.unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
      this.selectedItems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
      this.selectedItems.find(x => x.sl_no === this.sl_no).item_unit_code = vantransub_unit_id.unit_code;
      this.selectedItems.find(x => x.sl_no === this.sl_no).prd_stock_status = prd_data.prd_stock_status;
      this.selectedItems.find(x => x.sl_no === this.sl_no).add_perc = this.add_perc;
      this.selectedItems.find(x => x.sl_no === this.sl_no).net_rate = this.net_rate;
      this.selectedItems.find(x => x.sl_no === this.sl_no).rate = $('#vantran_purch_price').val();
      this.selectedItems.find(x => x.sl_no === this.sl_no).type_id = this.type_id;
  
     
     

    
     
        }
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      $('#gdtran-btn').show();

      
      this.net_rate = 0;
      this.add_perc = 0;
      this.vantran_purch_tax_rate=0
      this.vantran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();

    }
    // this.fieldName2.nativeElement.focus();
    if(this.branch_default_search){
      console.log('this.branch_default_search',this.branch_default_search);

      this.prdctSearch.focus();
    }else{
    this.fieldName2.nativeElement.focus();
    }
    this.isEdit =false;
  }
  editSaleItem1(prd_data, prd_barcode, transub_unit_id)
  {
    console.log('prd_data',prd_data);
    console.log('transub_unit_id',transub_unit_id);
    console.log('tran_purch_price',this.tran_purch_price);
    console.log('this.add_perc_edit',this.add_perc_edit);
    console.log('transub_unit_id.unit_base_qty',transub_unit_id.unit_base_qty);
    // console.log('tran_purch_price',this.tran_purch_price);
    
    
    console.log('this.previewDataitems',this.previewDataitems);
    $('.edit_row').removeClass('sel_row');
 
    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (this.gd_id_edit == undefined) {

      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if (!transub_unit_id) {
      this.printError.push({ 'transub_unit_idErr': 'Required' });
      $('#transub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#transub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm2();

    if (this.printError.length <= 0) {

      if(this.add_perc_edit){
        let nr = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
        this.tran_purch_price = nr;
      }

      this.box = this.transub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (transub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = transub_unit_id.unit_code;

        this.transub_qty = this.box * (transub_unit_id.unit_base_qty);
        this.tran_purch_price = this.tran_purch_price / (transub_unit_id.unit_base_qty);

      }


     // this.sl_no = (this.previewDataitems.length) + 1;

      this.transub_qty = Number(this.transub_qty);
      this.tran_purch_amount = this.tran_purch_price * this.transub_qty;
      var existingArray: any;
      if (this.previewDataitems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.previewDataitems,
          prd_data.branch_stock_id,
          transub_unit_id.sur_unit_id,
          this.tran_purch_price,
          prd_data.prd_barcode,
          transub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.tran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch2').css("border", "1px solid red");
        }
        else {
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).sl_no = this.sl_no;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_barcode = prd_data.prd_barcode;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_id = prd_data.prd_id;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_prd_id = prd_data.prd_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).prd_name = prd_data.prd_name;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_qty = this.transub_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).box = this.box;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).extstktrsub_gd_id = this.gd_id_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_rate = this.tran_purch_price;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).purchase_amount = this.tran_purch_amount;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_prod_id = prd_data.prd_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_stock_id = prd_data.bs_stock_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_branch_stock_id = prd_data.branch_stock_id;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_unit_id = transub_unit_id.sur_unit_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).vantransub_purch_rate = prd_data.bs_avg_prate;
    
          this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_name = transub_unit_id.unit_name;
    
          //this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_base = transub_unit_id.unit_base_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_code = transub_unit_id.unit_code;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).unit_code_base_qty = transub_unit_id.unit_base_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).base_unit_name = prd_data.base_unit_name;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).display_qty = this.display_qty;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).item_unit_code = transub_unit_id.unit_code;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).add_perc = this.add_perc_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).net_rate = this.net_rate_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).rate = this.tran_purch_price;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).type_id = this.type_id;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_add_perc = this.add_perc_edit;
          this.previewDataitems.find(x => x.sl_no === this.sl_no).purchase_tax_rate = this.tran_purch_tax_rate?this.tran_purch_tax_rate/transub_unit_id.unit_base_qty:0;
         
         
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_include_tax_rate = Number(this.net_rate_edit)/Number(transub_unit_id.unit_base_qty);
      
          this.previewDataitems.find(x => x.sl_no === this.sl_no).stocktrsub_rate = this.tran_purch_price;
     
          this.findTotalSum2();
        }

        // this.fieldName4.nativeElement.focus();
        if(this.branch_default_search){
          console.log('this.branch_default_search',this.branch_default_search);
    
          this.prdctSearch2.focus();
        }else{
        this.fieldName4.nativeElement.focus();
        }
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.previewDataitems.length;
      this.transub_qty = this.box;
      $('#brtran-btn').show();

      this.net_rate_edit = 0;
      this.add_perc_edit = 0;
      this.tran_purch_tax_rate=0
      this.tran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.transub_qty = 0;
      this.isEdit1 =false;


    }

  }

  deleteItemArray(data: any) {
    var rmvitem = confirm("Are you sure you want to remove this item from list ? ");
    if (rmvitem) {
      this.total_qty = this.previewDataitems.length;
      var type = data.sl_no;


      var i;
      for (i = this.previewDataitems.length - 1; i >= 0; --i) {
        if (this.previewDataitems[i].sl_no == type) {
          this.previewDataitems.splice(i, 1);
        }
      }
      this.findTotalSum2();

      if (this.previewDataitems.length == 0)
        $('#brtran-btn').hide();

    }
  }

  validateForm() {


    this.printError = [];
    // if ($('#prd_barcode').val() == "" || $('#prd_barcode').val() == undefined) {
    //   this.printError.push({ 'prd_barcodeErr': 'Required' });
    //   $('#prd_barcode').css("border", "1px solid red");
    // } else {
    //   $('#prd_barcode').css("border", "1px solid #dedede");
    // }
    if (this.vantransub_qty == undefined || this.vantransub_qty == null || this.vantransub_qty < 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#vantransub_qty').css("border", "1px solid red");
    } else {
      $('#vantransub_qty').css("border", "1px solid #dedede");
    }

    if ($('#vantran_purch_price').val() == "" || $('#vantran_purch_price').val() == undefined || $('#vantran_purch_price').val() <= 0) {
      this.printError.push({ 'vantran_purch_priceErr': 'Required' });
      $('#vantran_purch_price').css("border", "1px solid red");
    } else {
      if ($('#vantran_purch_price').val() > 0) {
        $('#vantran_purch_price').css("border", "1px solid #dedede");
      } else {
        $('#vantran_purch_price').css("border", "1px solid red");
        this.printError.push({ 'vantran_purch_priceErr': 'Required' });
      }

    }

    if ($('#vantransub_qty').val() == "" || $('#vantransub_qty').val() == undefined || $('#vantransub_qty').val() == 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#vantransub_qty').css("border", "1px solid red");
    }
    if(!this.below_purch_enable){
      if($('#vantran_purch_price').val() < this.purchase_rate){
        $('#vantran_purch_price').css("border", "1px solid red");
        this.printError.push({ 'vantran_purch_priceErr': 'Required' });
      }
    }

  }
  validateForm2() {


    this.printError = [];

    if (this.transub_qty == undefined || this.transub_qty == null || this.transub_qty < 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#transub_qty').css("border", "1px solid red");
    } else {
      $('#transub_qty').css("border", "1px solid #dedede");
    }

    if (this.gd_id_edit == undefined) {
      this.printError.push({ 'godown_id_edit': 'Required' });
      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if ($('#tran_purch_price').val() == "" || $('#tran_purch_price').val() == undefined) {
      this.printError.push({ 'tran_purch_priceErr': 'Required' });
      $('#tran_purch_price').css("border", "1px solid red");
    } else {
      $('#tran_purch_price').css("border", "1px solid #dedede");
    }

    if ($('#transub_qty').val() == "" || $('#transub_qty').val() == undefined || $('#transub_qty').val() == 0) {
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
      $('#transub_qty').css("border", "1px solid red");
    }
    if(!this.below_purch_enable){
      if($('#tran_purch_price').val() < this.purchase_rate){
        $('#tran_purch_price').css("border", "1px solid red");
        this.printError.push({ 'tran_purch_priceErr': 'Required' });
      }
    }

  }

  validateTransfer(formData) {




    this.printError = [];
    if (formData.tran_date == "" || formData.tran_date == undefined) {
      $('#tran_date').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      // if (formData.tran_date > new Date()) {
      //   $('#tran_date').css("border", "1px solid red");
      //   this.printError.push({ 'rec_date': 'Required' });
      // } else {
        $('#tran_date').css("border", "1px solid #dedede");
      // }

    }
    if (formData.branch_to == "" || formData.branch_to == undefined) {

      $('#branchid').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {

      $('#branchid').css("border", "1px solid #dedede");

    }
  }


  validateTransfer2(formData) {


    this.printError = [];
    if (formData.tran_date == "" || formData.tran_date == undefined) {
      $('#tran_date2').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      // if (formData.tran_date > new Date()) {
      //   $('#tran_date2').css("border", "1px solid red");
      //   this.printError.push({ 'rec_date': 'Required' });
      // } else {
        $('#tran_date2').css("border", "1px solid #dedede");
      // }


    }

    if (formData.branch_to == "" || formData.branch_to == undefined) {
      $('#branch_from').css("border", "1px solid red");
      this.printError.push({ 'vantransub_qtyErr': 'Required' });
    }
    else {
      $('#branch_from').css("border", "1px solid #dedede");
    }


  }

  convertRate(vantransub_unit_id,prd_det) {
   
    // for purch rate below validation
    this.purchase_rate=vantransub_unit_id.purch_rate;

    if (this.rate_status_type == 'purch') {
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(vantransub_unit_id.purch_rate); // Encrypted Rate
      } else {
        this.vantran_purch_price = vantransub_unit_id.purch_rate;
      }
      if(this.tax_rate){
        this.vantran_purch_tax_rate = vantransub_unit_id.purch_rate +  (prd_det.prd_tax / 100 * vantransub_unit_id.purch_rate);
      }
      else{
        this.vantran_purch_tax_rate =0;
      }
      

    } else {

      if (this.type_id == 1) {

      }


      if (this.rate_status_type == 'last_rate') {
        this.vantran_purch_price = vantransub_unit_id.last_rate;
        if(this.tax_rate){
          this.vantran_purch_tax_rate = vantransub_unit_id.last_rate +  (prd_det.prd_tax / 100 * vantransub_unit_id.last_rate);
        }
        else{
          this.vantran_purch_tax_rate = 0;
        }
       

      }
      else if (this.rate_status_type == 'avg_cost') {
        console.log('vantransub_unit_id',vantransub_unit_id);
        
        this.vantran_purch_price = vantransub_unit_id.purchavrg_rate;
        console.log(' this.vantran_purch_price ', this.vantran_purch_price );
        
        if(this.tax_rate){
          this.vantran_purch_tax_rate = vantransub_unit_id.purchavrg_rate +  (prd_det.prd_tax / 100 * vantransub_unit_id.purchavrg_rate);
          console.log(' this.vantran_purch_tax_rate ', this.vantran_purch_tax_rate );
        }
        else{
          this.vantran_purch_tax_rate = 0;
        }
       

      } else {

        
        var unit_rate = (this.sel_rate_type == 1) ? vantransub_unit_id.sur_unit_rate : vantransub_unit_id.sur_unit_rate2;
        this.vantran_purch_price = (unit_rate ? unit_rate : vantransub_unit_id.sale_rate);
        if(this.tax_rate){
        this.vantran_purch_tax_rate = (unit_rate ? unit_rate  +  (prd_det.prd_tax / 100 * unit_rate) : vantransub_unit_id.sale_rate +  (prd_det.prd_tax / 100 * vantransub_unit_id.sale_rate));
        }
        else{
          this.vantran_purch_tax_rate = 0;
        }
        // this.vantran_purch_price = vantransub_unit_id.sale_rate;
        this.rate_status_type = 'sale';
      }

    }

    this.update_rate('');
  }

  changeRate(rate, transub_unit_id,prd_det) {

  
    this.sel_rate_type = 1;
    if (rate == '2') {
      // this.vantran_purch_price = this.base_rate * (transub_unit_id.unit_base_qty);
      // this.vantran_purch_price = this.coreService.splitToDigit(transub_unit_id.purch_rate); // Encrypted Rate
      if (this.userEncpType) {
        this.vantran_purch_price = this.coreService.splitToDigit(transub_unit_id.purch_rate); // Encrypted Rate
      } else {
        this.vantran_purch_price = transub_unit_id.purch_rate;
       
      }
      this.tran_purch_price = transub_unit_id.purch_rate ;

      if(this.tax_rate){
        this.vantran_purch_tax_rate = transub_unit_id.purch_rate + (prd_det.prd_tax / 100 * transub_unit_id.purch_rate);
        this.tran_purch_tax_rate = transub_unit_id.purch_rate + (prd_det.prd_tax / 100 * transub_unit_id.purch_rate);
      }
      else{
        this.vantran_purch_tax_rate = 0;
        this.tran_purch_tax_rate = 0;
      }
     
      this.rate_status_type = 'purch';

    } else if (rate == '1') {

      this.vantran_purch_price = transub_unit_id.sale_rate;
      this.tran_purch_price = transub_unit_id.sale_rate;
      if(this.tax_rate){
      this.vantran_purch_tax_rate =transub_unit_id.sale_rate + (prd_det.prd_tax / 100 * transub_unit_id.sale_rate);
      this.tran_purch_tax_rate =transub_unit_id.sale_rate + (prd_det.prd_tax / 100 * transub_unit_id.sale_rate);
      }
      else{
        this.vantran_purch_tax_rate = 0;
        this.tran_purch_tax_rate = 0;

      }
      this.rate_status_type = 'sale';

    }
    else if (rate == '3') {
      this.vantran_purch_price = '';
      this.tran_purch_price = '';
      if(this.tax_rate){
      this.vantran_purch_tax_rate ='';
      this.tran_purch_tax_rate = '';
      }
      else{
        this.vantran_purch_tax_rate = 0;
        this.tran_purch_tax_rate = 0;

      }
      this.rate_status_type = 'custom';
    }
    else if (rate == '4') {
      this.vantran_purch_price = transub_unit_id.last_rate;
      this.tran_purch_price = transub_unit_id.last_rate;
      if(this.tax_rate){
      this.vantran_purch_tax_rate =transub_unit_id.last_rate +  (prd_det.prd_tax / 100 * transub_unit_id.last_rate);
      this.tran_purch_tax_rate = transub_unit_id.last_rate +  (prd_det.prd_tax / 100 * transub_unit_id.last_rate);
      }
      else{
        this.vantran_purch_tax_rate = 0;
        this.tran_purch_tax_rate = 0;

      }
      this.rate_status_type = 'last_rate';
    }
    else if (rate == '5') {
      console.log('transub_unit_id',transub_unit_id);
      
      this.vantran_purch_price = transub_unit_id.purchavrg_rate;
      this.tran_purch_price = transub_unit_id.purchavrg_rate;
      if(this.tax_rate){
      this.vantran_purch_tax_rate =transub_unit_id.purchavrg_rate +  (prd_det.prd_tax / 100 * transub_unit_id.purchavrg_rate);
      this.tran_purch_tax_rate = transub_unit_id.purchavrg_rate +  (prd_det.prd_tax / 100 * transub_unit_id.purchavrg_rate);
      }

      else{
        this.vantran_purch_tax_rate = 0;
        this.tran_purch_tax_rate = 0;

      }

      this.rate_status_type = 'avg_cost';
    }

    if(this.tax_rate ){
      this.net_rate = this. vantran_purch_tax_rate ? this. vantran_purch_tax_rate : 0;
      this.net_rate_edit = this.tran_purch_tax_rate ? this.tran_purch_tax_rate : 0;

    }
    else{
      this.net_rate = this.vantran_purch_price ? this.vantran_purch_price : 0;
      this.net_rate_edit = this.tran_purch_price ? this.tran_purch_price : 0;
    }

    
    this.add_perc = 0;
    this.add_perc_edit = 0;
    this.add_perc= (this.settings)?this.settings.ts_default_add_perc:0;
    this.add_perc_edit=(this.settings)?this.settings.ts_default_add_perc:0;
    if(!this.perc){
      console.log('!');
      
      this.update_rate(this.add_perc)

    }
    if(this.perc){
      console.log('=');
      
      this.update_rate_edit( this.add_perc_edit);
    }

    // this.update_rate_edit( this.add_perc_edit);
    console.log(' this.add_perc', this.add_perc);

  }


  convertRate2(transub_unit_id,prd_det) {

    // if (this.rate_status_type == 'purch') {
    //   this.tran_purch_price = this.base_rate * (transub_unit_id.unit_base_qty);

    // } else {

    //   if (this.type_id == 1) {

    //   }

    //   this.tran_purch_price = this.sale_rate * (transub_unit_id.unit_base_qty);
    //   this.rate_status_type = 'sale';

    // }

     // for purch rate below validation
     this.purchase_rate=transub_unit_id.purch_rate;

    if (this.rate_status_type == 'purch') {
      if (this.userEncpType) {
        this.tran_purch_price = this.coreService.splitToDigit(transub_unit_id.purch_rate); // Encrypted Rate
      } else {
        this.tran_purch_price = transub_unit_id.purch_rate;
      }
      if(this.tax_rate){
        this.tran_purch_tax_rate = transub_unit_id.purch_rate +  (prd_det.prd_tax / 100 * transub_unit_id.purch_rate);
      }
      else{
        this.tran_purch_tax_rate = 0;
      }
     
    }
    else if (this.rate_status_type == 'avg_cost') {
      console.log('tran_purch_price',this.tran_purch_price);
      
      this.tran_purch_price = transub_unit_id.purchavrg_rate;
      console.log('tran_purch_price',this.tran_purch_price);
      
      if(this.tax_rate){
        this.tran_purch_tax_rate = transub_unit_id.purchavrg_rate +  (prd_det.prd_tax / 100 * transub_unit_id.purchavrg_rate);
        console.log(' this.tran_purch_tax_rate ', this.tran_purch_tax_rate );
      }
      else{
        this.tran_purch_tax_rate = 0;
      }
     

    }
     else {
      if (this.type_id == 1) {
      }
      if (this.rate_status_type == 'last_rate') {
        this.tran_purch_price = transub_unit_id.last_rate;
        if(this.tax_rate){
        this.tran_purch_tax_rate = transub_unit_id.last_rate +  (prd_det.prd_tax / 100 * transub_unit_id.last_rate);
        }
        else{
          this.tran_purch_tax_rate = 0;
        }
      } else {
        this.tran_purch_price = transub_unit_id.sale_rate;
        if(this.tax_rate){
        this.tran_purch_tax_rate = transub_unit_id.sale_rate +  (prd_det.prd_tax / 100 * transub_unit_id.sale_rate);
        }
        else{
          this.tran_purch_tax_rate = 0;
        }
        this.rate_status_type = 'sale';
      }

    }

    this.update_rate_edit('');
  }

  appendItem(prd_data, prd_barcode, vantransub_unit_id) {
console.log('this.selectedItems',this.selectedItems);

    // alert(prd_data.prd_barcode);
    // alert(vantransub_unit_id.unit_base_qty);

    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];
      });

    }



    if (this.gd_id == undefined) {

      $('#godown_id').css("border", "1px solid red");
    }
    else {
      $('#godown_id').css("border", "1px solid #dedede");
    }









    if (!vantransub_unit_id) {
      this.printError.push({ 'vantransub_unit_idErr': 'Required' });
      $('#vantransub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#vantransub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm();

    if (this.printError.length <= 0) {

      if(this.add_perc){
        let nr = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
        this.vantran_purch_price = nr;
        if(this.tax_rate ){
          this.vantran_purch_tax_rate =parseFloat(this.vantran_purch_tax_rate) + (parseFloat(this.vantran_purch_tax_rate)*parseFloat(this.add_perc))/100;
        }
        else{
          this.vantran_purch_tax_rate =0;
        }
      }


      this.box = this.vantransub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      if (vantransub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = vantransub_unit_id.unit_code;

        this.vantransub_qty = this.box * (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_price = this.vantran_purch_price / (vantransub_unit_id.unit_base_qty);
        this.vantran_purch_tax_rate = this.vantran_purch_tax_rate / (vantransub_unit_id.unit_base_qty);

      }


      this.sl_no = (this.selectedItems.length) + 1;

      this.vantransub_qty = Number(this.vantransub_qty);
     
      if(this.tax_rate ){

        this.vantran_purch_amount = this.vantran_purch_tax_rate * this.vantransub_qty;
      }
      else{
        this.vantran_purch_amount = this.vantran_purch_price * this.vantransub_qty;
      }
      var existingArray: any;
      if (this.selectedItems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.selectedItems,
          prd_data.branch_stock_id,
          vantransub_unit_id.sur_unit_id,
          this.vantran_purch_price,
          this.vantran_purch_tax_rate,
          prd_data.prd_barcode,
          vantransub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.vantransub_qty;
        this.selectedItems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.vantran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch').css("border", "1px solid red");
        }

        else {
          this.selectedItems.push({
            "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "stocktrsub_prd_id": prd_data.prd_id,
            "prd_alias": prd_data.prd_alias,
            "prd_name": prd_data.prd_name,
            "prd_tax": prd_data.prd_tax,
            "vantransub_qty": this.vantransub_qty,
            "stocktrsub_qty": this.vantransub_qty,
            "box": this.box,
            "gd_id": this.gd_id,
            "purchase_rate": this.vantran_purch_price,
            'purchase_tax_rate': this.vantran_purch_tax_rate,
            "purchase_amount": this.vantran_purch_amount,
            "vantransub_prod_id": prd_data.prd_id,
            "vantransub_stock_id": prd_data.bs_stock_id,
            "vantransub_branch_stock_id": prd_data.branch_stock_id,
            "vantransub_unit_id": vantransub_unit_id.sur_unit_id,
            "vantransub_purch_rate": this.avgPurch_rate,
            "unit_name": vantransub_unit_id.unit_name,
            "unit_base": vantransub_unit_id.unit_base_qty,
            "unit_code": prd_data.base_unit_code,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": this.display_qty,
            "item_unit_code": vantransub_unit_id.unit_code,
            "add_perc": this.add_perc,
            "tax_rate" :this.tax_rate,
            "rate" : $('#vantran_purch_price').val(),
            "type_id":this.type_id,
            // "prd_stock_status": prd_data.prd_stock_status,
            "net_rate" :this.net_rate,

          });

        }
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.selectedItems.length;
      this.vantransub_qty = this.box;
      $('#gdtran-btn').show();

      this.net_rate = 0;
      this.add_perc = 0;
      this.vantran_purch_price = 0;
      this.vantran_purch_tax_rate =0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.vantransub_qty = 0;
      this.findTotalSum();

    }
    if(this.branch_default_search){
      console.log('branch_default_search',this.branch_default_search);
      // document.getElementById("prdctSearch").focus();
      this.prdctSearch.focus();
    }else{
      this.fieldName2.nativeElement.focus();
    }
    // this.fieldName2.nativeElement.focus();

  }
  findTotalSum2() {
    this.stocktr_amount = 0;
    this.previewDataitems.forEach(element => {
      if(this.tax_rate ){
        this.stocktr_amount += Number(element.stocktrsub_include_tax_rate * element.stocktrsub_qty);
      }
      else{
        this.stocktr_amount += Number(element.stocktrsub_rate * element.stocktrsub_qty);
      }
      
    });
  }

  clearForm() {
    this.selectedItems = [];
    this.branchtran_request_id =0;
    this.getTrasferSetting();
    this.branch_id = 0;
    this.balance = 0;
    this.batch_name = '';
    this.prd_id = '';
    this.brcode = '';
    this.prd_units = [];
    this.type_id = 0;
    this.vantransub_qty = '';
    this.default_unit = [];

    this.vantran_purch_price = 0;
    this.vantran_purch_tax_rate =0;

    setTimeout(() => {
      if(this.branch_default_search){
        this.prdctSearch.focus();
      }else{
        this.fieldName2.nativeElement.focus();
      }
    }, 500);

  }


  findTotalSum() {
    this.total_amount = 0;
    this.selectedItems.forEach(element => {
      if(this.tax_rate ){
        this.total_amount += Number(element.purchase_tax_rate * element.vantransub_qty);
      }
      else{
        this.total_amount += Number(element.purchase_rate * element.vantransub_qty);
      }
      
    });
  }

  vanTransfer(formData) {

 

  

    var confrm = confirm("Are you sure?");
    if (confrm) {

      this.validateTransfer(formData.value);

      if (this.stockItems.length == 0 && this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.selectedItems;
        formData.value.vantran_price = this.total_amount;
        formData.value.branchtran_request_id = this.branchtran_request_id;
        this.apiService.branchTransfer(formData.value).subscribe((res) => {
          this.pageLoadingImg = false;
          $('.close').click();
          this.coreService.showMessage(res.message);
          this.ngOnInit();

          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          this.tran_purch_tax_rate = 0;
          this.tran_purch_price = 0;
          this.prdn_date = new Date();
          this.productionPreview(res.cat_id,'');
          $("#previewButton").click();
          this.gd_id = Number(this.userdefltGwn);



        });
      }
      else {

      }
    }
  }
  onShowRateChange(showrate){ 
  if(showrate){
    localStorage.setItem("showrate","1");
    console.log('this.showrate',this.showrate);
  
  }else{
    localStorage.setItem("showrate","0");
    console.log('this.showrate',this.showrate);
  
  }
   
 
  }



  updateTransfer(formData) {

    var confrm = confirm("Are you sure you want to update?");
    if (confrm) {

      this.validateTransfer2(formData.value);



      if (this.printError.length <= 0) {
        this.pageLoadingImg = true;

        formData.value.items = this.previewDataitems;
        formData.value.stocktr_amount = this.stocktr_amount;

        this.apiService.updateTransfer(formData.value).subscribe((res) => {
          console.log('rs',res);
          
          this.pageLoadingImg = false;
          this.ngOnInit();
          
          // this.productionPreview(formData.stocktr_id);
          // this.previewData = this.previewDataitems  =[];

          $('.close').click();

          this.coreService.createfunct(formData, this.resultobj, res.message);
          this.selectedItems = [];
          this.total_qty = 0;
          this.total_amount = 0;
          $('#brtran-btn').hide();


        });
      }
      else {

      }
    }
  }

  resetItemsearch() {
    this.vantran_purch_price = 0;
    this.vantran_purch_tax_rate =0;

    this.prd_barcode = '';
    this.default_unit = [];
    this.vantransub_qty = '';
  }
  barcodeSearch(brcode: string, branchto: string, type: string,act_type='') {
    this.net_rate = 0;
    this.add_perc = 0;
    this.net_rate_edit = 0;
    this.add_perc_edit = 0;
    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();
    searchval.append("brcode", brcode);
    searchval.append("branch_received", branchto);
    searchval.append("godown_id", this.gd_id);

    this.apiService.searchVanProductsExtStkTrans(searchval).subscribe((res) => {
      // this.prd_all = res['data'];
      this.prd_id = res['data'][0];
      this.setValues(res['data'][0], type,act_type);

    });
  }

  searchProducts(search: string, brcode: string, branchto: string) {

    this.resetItemsearch();
    this.prd_all = [];
    let searchval = new FormData();

    if (search) {
      searchval.append("keyword", search);
      searchval.append("branch_received", branchto);
      if(this.commAll==1){
        searchval.append("alias",'1');
      }
      searchval.append("godown_id", this.gd_id);
     
    }

    this.apiService.searchProductsForTransFer(searchval).subscribe((res) => {
      
       //this.prd_all =  res['data'];
      //   this.prd_all =  res['data'].filter(x => x.branch_qty != "" || x.branch_qty >= 0);
      
      if(this.gd_id == 0){
      
        this.prd_all =  res['data'];
       }
       if(this.gd_id > 0 && this.search_type==2){
       
        this.prd_all =  res['data'].filter(x => x.branch_qty != "");
       }
       

    });
  }

  getNxtVanTranId() {
    let searchval = new FormData();
    this.apiService.getNxtVanTranId(searchval).subscribe((res) => {
      this.tran_id = res['data'].tran_id;
    });

  }
  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
     
    });

  }
  prodDetails(prd_id,branchto: string,actn_type=''){
   
    if(prd_id){
      this.vantransub_qty=0;
      const searchval = new FormData();
      searchval.append('prd_id', prd_id.prd_id);
      searchval.append("branch_received", branchto);
      searchval.append("godown_id", this.gd_id);
      this.loading=true;
      this.apiService.gettrnsferinfProdDetails(searchval).subscribe((res) => {
        this.loading=false;
        this.selctedProd = res['data'][0];
       this.setValues(this.selctedProd,1,actn_type)

     
      });
      this.add_perc= (this.settings)?this.settings.ts_default_add_perc:0;
      this.add_perc_edit=(this.settings)?this.settings.ts_default_add_perc:0;
    }
    
  }
  setValues(prd_data, type,actn_type='') {

  
    
    

    this.net_rate = 0;
    this.add_perc = 0;
    this.net_rate_edit = 0;
    this.add_perc_edit = 0;
    console.log('prd_data',prd_data);
    
    if (prd_data) {
      // for purchaserate validation
        this.purchase_rate=prd_data.bs_prate;
        console.log(this.purchase_rate+'=this.purchase_rate');
        
      // if(prd_data.prd_id){
        this.getStockDetails(prd_data.prd_id, this.gd_id);
        this.getBranchWiseStock(prd_data.prd_id);
        this.add_perc= (this.settings)?this.settings.ts_default_add_perc:0;
        this.add_perc_edit=(this.settings)?this.settings.ts_default_add_perc:0;
        console.log(' this.add_perc', this.add_perc);
        
      }else{
        this.selctedProd=[];
        return false;
      }
    if (prd_data.prd_base_unit_id) {

      if (type > 0) {
        this.fieldName1.nativeElement.focus();
      }
      else {
    
        this.fieldName3.nativeElement.focus();
      }
    }

    this.salestatus = 1;


    this.avgPurch_rate = prd_data.bs_avg_prate;
    this.base_rate = prd_data.bs_prate;



    if (this.type_id == 2) {


      if (this.userEncpType) {
        this.tran_purch_price = this.vantran_purch_price = this.sale_rate = this.coreService.splitToDigit(prd_data.bs_prate); // Encrypted Rate
      } else {
        this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_prate;

      }
      if(this.tax_rate){
        this.tran_purch_tax_rate =  this.vantran_purch_tax_rate = this.sale_tax_rate = prd_data.bs_prate + (prd_data.prd_tax / 100 * prd_data.bs_prate);
      }
      else{
        this.tran_purch_tax_rate = 0;
      }
     
      this.rate_status_type = 'purch';

    } else if (this.type_id == 1) {
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;
      if(this.tax_rate){
      this.tran_purch_tax_rate =  this.vantran_purch_tax_rate = this.sale_tax_rate = prd_data.bs_srate + (prd_data.prd_tax / 100 * prd_data.bs_srate);
      }
      else{
        this.tran_purch_tax_rate = 0;
      }
      this.rate_status_type = 'sale';

    }

    else if (this.type_id == 3) {
      this.vantran_purch_price = '';
      this.rate_status_type = 'custom';
      if(this.tax_rate){
      this.tran_purch_tax_rate =  '';
      }
      else{
        this.tran_purch_tax_rate =  0;
      }
    }
    else if (this.type_id == 5) {
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_avg_prate;
      if(this.tax_rate){
      this.tran_purch_tax_rate =  this.vantran_purch_tax_rate = this.sale_tax_rate = prd_data.bs_avg_prate + (prd_data.prd_tax / 100 * prd_data.bs_avg_prate);
      }
      else{
        this.tran_purch_tax_rate =0;
      }
      this.rate_status_type = 'avg_cost';
    }
    else if (this.type_id == 1) { 
      this.type_id = 1;
      this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.bs_srate;
      if(this.tax_rate){
      this.tran_purch_tax_rate =  this.vantran_purch_tax_rate = this.sale_tax_rate = prd_data.bs_srate + (prd_data.prd_tax / 100 * prd_data.bs_srate);
      }
      else{
        this.tran_purch_tax_rate = 0;
      }
      this.rate_status_type = 'sale';
    }


    if (this.type_id == 4) {

      this.type_id = 4;
      if(prd_data.last_rate > 0){
        this.tran_purch_price = this.vantran_purch_price = this.sale_rate = prd_data.last_rate;
        if(this.tax_rate){
          this.tran_purch_tax_rate =  this.vantran_purch_tax_rate = this.sale_tax_rate = prd_data.last_rate + (prd_data.prd_tax / 100 * prd_data.last_rate);
        }
        else{
          this.tran_purch_tax_rate = 0;
        }
       
      }
      
      this.rate_status_type = 'last_rate';

    }

    if(this.tax_rate ){
      this.net_rate = this.vantran_purch_tax_rate;
      this.net_rate_edit = this.tran_purch_tax_rate;
    }
    else{
      this.net_rate = this.vantran_purch_price;
      this.net_rate_edit = this.tran_purch_price;
    }
   


    this.prd_barcode = prd_data.prd_barcode;
    this.prd_units = prd_data.prd_units;
    
    // this.transub_qty = this.vantransub_qty = '1';
    if(prd_data.prd_default_unit){
     
      
      this.default_unit = prd_data.prd_default_unit;
      if(actn_type=='edit'){
        this.convertRate2(this.default_unit,prd_data)
      }else{
        this.convertRate(this.default_unit,prd_data)
      }
      
    }else{
      if (!prd_data.eanunit) {
        this.default_unit = {
          "unit_code": prd_data.base_unit_code, "unit_name": prd_data.base_unit_name,
          "sur_unit_id": prd_data.prd_base_unit_id, "unit_base_qty": 1,
          "sale_rate": prd_data.bs_srate, "purch_rate": prd_data.bs_prate,
          "avg_cost": prd_data.bs_avg_prate,
          "last_rate": prd_data.last_rate,
          "purchavrg_rate": prd_data.bs_avg_prate,

        };
      }
      else {
        this.default_unit = prd_data.eanunit;
        if(actn_type=='edit'){
          this.convertRate2(this.default_unit,prd_data)
        }else{
          this.convertRate(this.default_unit,prd_data)
        }
        // this.convertRate(this.default_unit,prd_data);
      }
    }

    // this.type_id = 1;

    if (this.gd_id > 0) {

    }
    else {
      this.gd_id = 0;
    }


  }
  getBranchWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.branchStocksTotal = 0;

    this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
      this.branchStocks = res['data']['data'];
      this.branchStocksTotal = res['data']['total'];

    });

  }



  voidTransfer(tran_id) {
    let formData = new FormData();
    var confrm = confirm("Are you sure you want to void transfer? ");
    formData.append("tran_id", tran_id);
    if (confrm) {
      this.voidInprogress[tran_id] = true;
      this.apiService.voidTransfer(formData).subscribe((res) => {
        this.ngOnInit();
        this.pageLoadingImg = false;
        this.coreService.createfunct(formData, this.resultobj, res.message);
        this.selectedItems = [];
        this.total_qty = 0;
        this.total_amount = 0;




      });

    }

    else {

    }
  }













  accpgEnd(curr, end) {
    if (curr == end)
      this.accpgend = true;
    else
      this.accpgend = false;

  }

  accpgStart(curr) {
    if (curr == 1)
      this.accpgstart = true;
    else
      this.accpgstart = false;

  }


  appendItem2(prd_data, prd_barcode, transub_unit_id) {

console.log('prd_data',prd_data);
console.log('previewDataitems',this.previewDataitems);
console.log('transub_unit_id',transub_unit_id);


    if (!prd_data.branch_stock_id) {

      let searchval = new FormData();
      searchval.append("brcode", prd_barcode);
      this.apiService.searchVanProducts(searchval).subscribe((res) => {

        prd_data = res['data'][0];

      });

    }
    if (this.gd_id_edit == undefined) {

      $('#godown_id_edit').css("border", "1px solid red");
    }
    else {
      $('#godown_id_edit').css("border", "1px solid #dedede");
    }

    if (!transub_unit_id) {
      this.printError.push({ 'transub_unit_idErr': 'Required' });
      $('#transub_unit_id').css("border", "1px solid red");
    }
    else {
      $('#transub_unit_id').css("border", "1px solid #dedede");
    }
    this.validateForm2();

    if (this.printError.length <= 0) {
      if(this.add_perc_edit){
        let nr = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
        this.tran_purch_price = nr;

        if(this.tax_rate ){
          this.tran_purch_tax_rate =parseFloat(this.tran_purch_tax_rate) + (parseFloat(this.tran_purch_tax_rate)*parseFloat(this.add_perc_edit))/100;
        }
        else{
          this.tran_purch_tax_rate =0;
        }
      }
      this.box = this.transub_qty;
      this.display_qty = '';
      this.unit_qty = '';
      console.log('transub_unit_id');
      console.log(transub_unit_id);
      this.display_qty = transub_unit_id.unit_code;
      
      if (transub_unit_id.unit_base_qty != 1) {

        this.unit_qty = this.box;
        this.display_qty = transub_unit_id.unit_code;

        this.transub_qty = this.box * (transub_unit_id.unit_base_qty);
        this.tran_purch_price = this.tran_purch_price / (transub_unit_id.unit_base_qty);
        this.tran_purch_tax_rate = this.tran_purch_tax_rate / (transub_unit_id.unit_base_qty);
      }


      this.sl_no = (this.previewDataitems.length) + 1;

      this.transub_qty = Number(this.transub_qty);

      if(this.tax_rate ){
        this.tran_purch_amount = this.tran_purch_tax_rate * this.transub_qty;
      }
      else{
        this.tran_purch_amount = this.tran_purch_price * this.transub_qty;
      }
    
      var existingArray: any;
      if (this.previewDataitems.length < 0) {
        // var existingArray = [];
      }
      else {

        existingArray = this.coreService.checkTransferExist(this.previewDataitems,
          prd_data.branch_stock_id,
          transub_unit_id.sur_unit_id,
          this.tran_purch_price,
          this.tran_purch_tax_rate,
          prd_data.prd_barcode,
          transub_unit_id.unit_code, prd_data.prd_id);
      }


      if (existingArray && existingArray.length > 0) {


        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantransub_qty += this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).item_amount = this.transub_qty;
        this.previewDataitems.find(x => x.sl_no === existingArray[0].sl_no).vantran_purch_amount += this.tran_purch_amount;
      }
      else {

        if (!prd_data.prd_id) {
          $('#prdctSearch2').css("border", "1px solid red");
        }
        else {
          this.previewDataitems.push({
            "sl_no": this.sl_no, "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "prd_name": prd_data.prd_name,
            "prd_tax": prd_data.prd_tax,
            "stocktrsub_qty": this.transub_qty,
            "box": this.box,
            "stocktrsub_gd_id": this.gd_id_edit,
            "stocktrsub_rate": this.tran_purch_price,
            "stocktrsub_include_tax_rate": this.tran_purch_tax_rate,
            "purchase_amount": this.tran_purch_amount,
            "vantransub_prod_id": prd_data.prd_id,
            "vantransub_stock_id": prd_data.bs_stock_id,
            "vantransub_branch_stock_id": prd_data.branch_stock_id,
            "vantransub_unit_id": transub_unit_id.sur_unit_id,
            "vantransub_purch_rate": prd_data.bs_avg_prate,
            "unit_name": transub_unit_id.unit_name,
            "unit_base": transub_unit_id.unit_base_qty,
            "unit_code_base_qty": transub_unit_id.unit_base_qty,
            "unit_code": transub_unit_id.unit_display,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": this.display_qty,
            "item_unit_code": transub_unit_id.unit_code,
            "stocktrsub_add_perc": this.add_perc_edit,
            "stocktrsub_tax_rate_status" :this.tax_rate,
            "add_perc" : this.add_perc_edit,
            "net_rate_edit" :this.net_rate_edit,
            "rate" : $('#tran_purch_price').val(),
            "type_id":this.type_id,
            'purchase_tax_rate': this.tran_purch_tax_rate,
            "tran_purch_tax_rate":this.tran_purch_tax_rate,
           

          });
          
          
          this.findTotalSum2();
        }
       
        // this.fieldName4.nativeElement.focus();
      }






      //  this.vantransub_qty = Number(this.vantransub_qty);
      this.total_qty = this.previewDataitems.length;
      this.transub_qty = this.box;
      $('#brtran-btn').show();

      this.net_rate_edit = 0;
      this.add_perc_edit = 0;
      this.tran_purch_price = 0;
      this.brcode = '';
      this.prd_barcode = '';
      this.prd_id = '';
      this.default_unit = [];
      this.transub_qty = 0;

      if(this.branch_default_search){
        this.prdctSearch2.focus();
      }else{
        this.fieldName4.nativeElement.focus();
      }
    }
  }

  checkEnterClicked(e) {
    if (e.keyCode === 13) {
      $("#plusButton").click();
    }
  }

  update_rate(e){
    if(this.add_perc){
      if(this.tax_rate ){
        this.net_rate = parseFloat(this.vantran_purch_tax_rate) + (parseFloat(this.vantran_purch_tax_rate)*parseFloat(this.add_perc))/100;
      }
      else{
        this.net_rate = parseFloat(this.vantran_purch_price) + (parseFloat(this.vantran_purch_price)*parseFloat(this.add_perc))/100;
      }
      
    }else{
      if(this.tax_rate ){
        this.net_rate = this.vantran_purch_tax_rate;
      }
      else{
        this.net_rate = this.vantran_purch_price;
      }

     
    }
  }
  update_rate_edit(e){
    if(this.add_perc_edit){

      if(this.tax_rate ){
        this.net_rate_edit = parseFloat(this.tran_purch_tax_rate) + (parseFloat(this.tran_purch_tax_rate)*parseFloat(this.add_perc_edit))/100;
      }
      else{
        this.net_rate_edit = parseFloat(this.tran_purch_price) + (parseFloat(this.tran_purch_price)*parseFloat(this.add_perc_edit))/100;
      }
    }else{
      if(this.tax_rate ){
        this.net_rate_edit = this.tran_purch_tax_rate;
      }
      else{
        this.net_rate_edit = this.tran_purch_price;
      }
      
    }
  }

  checkEnterClicked2(e) {
    if (e.keyCode === 13) {
      $("#plusButton2").click();
    }
  }


  selectAllContent(e) {
    e.target.select();
  }
  importFromPurchase(purchid){
    this.purchLoadingImg = true;
    const searchval = new FormData();
    searchval.append('purch_id', purchid);
    this.selectedItems = [];
    this.clearForm();
    this.apiService.getpurchaseDetails(searchval).subscribe((res) => {
      if (res.error != null) {
        this.coreService.showMessage('Failed To Import, Invalid Purchase No', 3000);
      } else{

        
        
        res.data.items.forEach((prd_data, index) => {
          if(this.tax_rate){
            this.vantran_purch_tax_rate =  prd_data.purchsub_rate +   (prd_data.prd_tax / 100 * prd_data.purchsub_rate);
          }
          else{
            this.vantran_purch_tax_rate = 0;
          }
        
          this.selectedItems.push({
            "sl_no": index, 
            "prd_barcode": prd_data.prd_barcode,
            "prd_id": prd_data.prd_id,
            "stocktrsub_prd_id": prd_data.prd_id,
            "prd_name": prd_data.prd_name,
            "vantransub_qty": prd_data.purchsub_qty,
            "stocktrsub_qty": prd_data.purchsub_qty,
            "box": prd_data.unit_base_qty * prd_data.purchsub_qty,
            "gd_id": this.gd_id,
            "purchase_rate": prd_data.purchsub_rate,
            'purchase_tax_rate': this.vantran_purch_tax_rate,
            "purchase_amount": prd_data.purchase_amount,
            "vantransub_prod_id": prd_data.prd_id,
            "vantransub_stock_id": prd_data.purchsub_stock_id,
            "vantransub_branch_stock_id": prd_data.purchsub_branch_stock_id,
            "vantransub_unit_id": prd_data.purchased_unit.unit_id,
            // "vantransub_purch_rate": prd_data.purchsub_rate,
            "vantransub_purch_rate": prd_data.avg_purchRate,
            "unit_name": prd_data.purchased_unit.unit_name,
            "unit_base": prd_data.punit_base_qty,
            "unit_code": prd_data.base_unit_code,
            "base_unit_name": prd_data.base_unit_name,
            "display_qty": prd_data.punit_code,
            "item_unit_code": prd_data.unit_code,

            "add_perc": this.add_perc,
            "tax_rate" :this.tax_rate,

          });
        });

        this.total_qty = this.selectedItems.length;
        $('#gdtran-btn').show();
        this.net_rate = 0;
        this.add_perc = 0;
        this.vantran_purch_price = 0;
        this.vantran_purch_tax_rate =0;
        this.brcode = '';
        this.prd_barcode = '';
        this.prd_id = '';
        this.default_unit = [];
        this.vantransub_qty = 0;
        this.findTotalSum();
      }
    
      
      this.purchLoadingImg = false;
    });
  }
  langChange(){
    this.translate.get(['Table.mrp','HOME.purchase_rate','HOME.CUSTOM','Common.last_transfer_rate','Common.avg_cost']).subscribe((res: string) => {    
      
      this.rate_all = [
        { type_id: 1, name:  res['Table.mrp'] },
        { type_id: 2, name:  res['HOME.purchase_rate'] },
        { type_id: 3, name:  res['HOME.CUSTOM'] },
        { type_id: 4, name:  res['Common.last_transfer_rate'] },
        { type_id: 5, name:  res['Common.avg_cost'] },
      ];

    });
    
  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.search_type = this.sales_settings.search_type;
      }
    });

  }

  enterRate(val,prd_det){
   
    this.vantran_purch_price =Number(val);
    if(this.tax_rate ){
      this.vantran_purch_tax_rate =  this.vantran_purch_price +   (prd_det.prd_tax / 100 * this.vantran_purch_price);
    }
    else{
      this.vantran_purch_tax_rate = 0; 
    }

    this.update_rate('');
   
  }

  enterTaxRate(val,prd_det){
   

    this.vantran_purch_tax_rate =Number(val);
   
    let value = this.vantran_purch_tax_rate- ((this.vantran_purch_tax_rate * 100) / (prd_det.prd_tax + 100));
    this.vantran_purch_price  = this.vantran_purch_tax_rate- value;
    this.update_rate('');
  }

  enterTaxRate1(val,prd_det){
  

    this.tran_purch_tax_rate =Number(val);
   
    let value = this.tran_purch_tax_rate- ((this.tran_purch_tax_rate * 100) / (prd_det.prd_tax + 100));
    this.tran_purch_price  = this.tran_purch_tax_rate- value;
    this.update_rate_edit('');
  }

  enterRate1(val,prd_det){
  
    this.tran_purch_price =Number(val);
    if(this.tax_rate ){
      this.tran_purch_tax_rate =  this.tran_purch_price +   (prd_det.prd_tax / 100 * this.tran_purch_price);
    }
    else{
      this.tran_purch_tax_rate = 0; 
    }

    this.update_rate_edit('');
   
  }

  getHeaderImage() {
    this.apiService.getSettings().subscribe((res) => {
  this.base_url = this.apiService.PHP_API_SERVER
  this.img_url = res.data.prnt_common_header_img;
  this.header_type = res.data.prnt_header_type;
    this.logoImg = res.data.prnt_header_logo;
    this.logoImgUrl = res.data.prnt_header_logo_img_path;
    });
  }

  getGeneralSetting() {

    this.apiService.getGeneralSetting().subscribe((res) => {
  
      if(res['data']){
        this.g_settings = res['data'];
        this.generalSearch_type = this.g_settings.g_s_search_type;
      }else{
        this.generalSearch_type=0;
      }
      
    });
  
  }

  listTransferRequests() {
    let searchval = new FormData();
    this.pageLoadingImg = true;
    // searchval.append("status", '0');
    this.apiService.listBranchTransferRequests(searchval, 1).subscribe((res) => {

      this.request_list = res['data'];


      this.pageLoadingImg = false;
      this.request_curpage = res['current_page'];
      this.request_from = res['from'];
      this.request_lastpage = res['last_page'];
      this.pgEnd(this.request_curpage, this.request_lastpage);
      this.pgStart(this.request_curpage);
      this.request_slnum = 0;
    });
  }

  requestPreview(stkreq_id,edit_pending) {

    let searchval = new FormData();
    searchval.append("stkreq_id", stkreq_id );
    this.apiService.transferReqPreview(searchval).subscribe((res) => {
      this.previewReqData = res;
    
      //this.previewDataitems = res.items;
      //$('#brtran-btn').show();
      // this.transferdate = this.datePipe.transform(res.stocktr_date, 'dd/MM/yyyy');
      // var to = this.transferdate.split("/");
      // this.transferdate = new Date(to[2], to[1] - 1, to[0]);
    });

  }

  acceptRequest(stkreq_id){
    
    $('#gdtran-btn').show();
    console.log("accept");
    console.log(stkreq_id);
    this.isEdit =false;
   
    let searchval = new FormData();
    searchval.append("stkreq_id", stkreq_id );
    this.apiService.transferReqPreview(searchval).subscribe((res) => {
         
         this.branchtran_request_id =res.stkreq_id;
         this.branch_id =res.branch_id;
         this.gd_id =res.stkreq_gd_id;
         this.stockItems =res.nonSockproducts;

      

        
        //  this.tran_id=data.vantran_id;
        //  this.getNxtVanTranId();
        //  this.vantran_date=new Date(res.VanTransferRequest.vtrq_date);
        //  this.vantran_godown_id=data.VanTransferRequest.vtrq_godown_id;
        //  this.vantran_van_id=data.VanTransferRequest.vtrq_van_id;
        //  this.vantran_request_id = data.VanTransferRequest.vtrq_id;
        //  this.vantran_notes =data.VanTransferRequest.vtrq_notes;
        //  this.vantran_purch_price =0;
         // this.selectedItems=data.items;
         // console.log(this.selectedItems.length +'  helooooo');
         
        //  this.total_amount=data.vantran_price;
        //  this.selectedItems=[];

        this.getTrasferSetting();
        this.selectedItems=[];
        res.items.forEach((element, index) => {
     
           if(element.unit_type){
             this.display_qty='';
           }else{
             this.display_qty=element.prod.prd_default_unit.unit_code;
           }

           if(this.tax_rate){
            element.purchase_tax_rate =  element.prod.rate +   (element.prod.prd_tax / 100 * element.prod.rate);
          }
          else{
            element.purchase_tax_rate = 0;
          }

         
          this.selectedItems
            this.selectedItems.push({
     
              "sl_no": index+1, "prd_barcode": element.prd_barcode,
              "prd_id": element.prd_id,
              "stocktrsub_prd_id": element.prd_id,
              "prd_name": element.prd_name,
              "prd_alias": element.prd_alias,
              "vantransub_qty": element.stkreqsub_qty,
              "stocktrsub_qty": element.stkreqsub_qty,
              "box": element.stkreqsub_qty,
              "gd_id": element.stkreqsub_gd_id,
 
 
              "purchase_rate": element.prod.rate,
              'purchase_tax_rate': element.purchase_tax_rate,
              "purchase_amount": (element.purchase_tax_rate * element.unit_base) * (element.stkreqsub_qty / element.unit_base),
              "vantransub_prod_id": element.prod.prd_id,
              "vantransub_stock_id": element.prod.bs_stock_id,
              "vantransub_branch_stock_id": element.prod.branch_stock_id,
              "vantransub_unit_id": element.stkreqsub_unit_id,
              "vantransub_purch_rate":  element.prod.AvgPurchrate,
              "unit_name": element.unit_code,
               "unit_base": element.unit_base,
              "unit_code": element.unit_base,
              "base_unit_name": element.prod.unit_name,
              "display_qty": element.display_qty,
              "item_unit_code": element.unit_code,
              "add_perc": this.add_perc,
              "tax_rate" :this.tax_rate,
              "type_id":this.type_id,
              "net_rate" :element.purchase_tax_rate * element.unit_base,
              "rate":element.prod.rate * element.unit_base, 
              
              
      
            });

         
           

          
       



          
         });
     
         // code to get total derived qty
        //  this.total_drvd_qty = 0;
        //  this.selectedItems.forEach(element => {
        //    this.total_drvd_qty += Number(element.box);
        //  });
         
        //  this.total_qty = this.selectedItems.length;
        //  if(this.selectedItems.length !=0){
        //    this.vanTransBtn1 = true;
        //  }
        //  this.existingArray=this.selectedItems;
         this.findTotalSum();

        

    });




    
  }

  rejectRequest(stkreq_id){
    


    var confrm = confirm("Are you sure?");
    if (confrm) {

      const searchval = new FormData();
      searchval.append('stkreq_id', stkreq_id);
      
      this.apiService.stockBranchTransferReqReject(searchval).subscribe((res) => {
          
     
        this.coreService.showMessage(res.message);
         
       
         
        this.listTransferRequests();
        this.pendingTransferRequests();
  
      });

    }
  }

  pendingTransferRequests() {

    this.apiService.pendingStockTranReqCount().subscribe((res) => {

      this.pendingTransferRequest = res['data'];
     
      
    });

  }

}
