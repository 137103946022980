// stock-routing.module.ts

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { TransactionDashComponent } from "./transaction-dash/transaction-dash.component";
import { ReceiptComponent } from "./transaction-dash/vouchers/receipt/receipt.component";
import { PaymentComponent } from "./transaction-dash/vouchers/payment/payment.component";
import { JournalComponent } from "./transaction-dash/vouchers/journal/journal.component";
import { ContraComponent } from "./transaction-dash/vouchers/contra/contra.component";
import { VouchersComponent } from "./transaction-dash/vouchers/vouchers.component";
import { BranchTransferReceiptComponent } from "./transaction-dash/branch-transfer-receipt/branch-transfer-receipt.component";
import { InterBranchStockTransferComponent } from "./transaction-dash/branch-transfer-receipt/inter-branch-stock-transfer/inter-branch-stock-transfer.component";
import { BranchStockReceiptComponent } from "./transaction-dash/branch-transfer-receipt/branch-stock-receipt/branch-stock-receipt.component";
import { SalesComponent } from "./transaction-dash/sales/sales.component";
import { SalesRetWithoutInvComponent } from "./transaction-dash/sales/sales-ret-without-inv/sales-ret-without-inv.component";
import { InvoiceDueReceiptComponent } from "./transaction-dash/sales/invoice-due-receipt/invoice-due-receipt.component";
import { SalesVoidComponent } from "./transaction-dash/sales/sales-void/sales-void.component";

import { SalesReturnVoidComponent } from "./transaction-dash/sales/sales-return-void/sales-return-void.component";
import { SalesReturnComponent } from "./transaction-dash/sales/sales-return/sales-return.component";

import { InterBranchReceiptComponent } from "./transaction-dash/vouchers/inter-branch-receipt/inter-branch-receipt.component";
import { InterBranchPaymentComponent } from "./transaction-dash/vouchers/inter-branch-payment/inter-branch-payment.component";
import { ReceiptDashComponent } from "./transaction-dash/vouchers/receipt-dash/receipt-dash.component";
import { PaymentDashComponent } from "./transaction-dash/vouchers/payment-dash/payment-dash.component";
import { SalesFormComponent } from "./transaction-dash/sales/sales-form/sales-form.component";
import { SalesQuoatationComponent } from "./transaction-dash/sales/sales-quoatation/sales-quoatation.component";
import { SalesInvoiceComponent } from "./transaction-dash/sales/sales-invoice/sales-invoice.component";
import { DeliveryChallanComponent } from "./transaction-dash/challan/delivery-challan/delivery-challan.component";
import { QuickSaleComponent } from "./transaction-dash/sales/quick-sale/quick-sale.component";
import { ExpenseTaxPaymentComponent } from "./transaction-dash/vouchers/expense-tax-payment/expense-tax-payment.component";
import { ExtStockTransferComponent } from './transaction-dash/branch-transfer-receipt/ext-stock-transfer/ext-stock-transfer.component';
import { ExtStockReceiptComponent } from './transaction-dash/branch-transfer-receipt/ext-stock-receipt/ext-stock-receipt.component';
import { SalesOrderComponent } from "./transaction-dash/sales/sales-order/sales-order.component";
import { PosComponent } from "../pos/pos.component";
import { ChequeComponent } from "./transaction-dash/vouchers/cheque/cheque.component";
import { CreditNoteComponent } from "./transaction-dash/vouchers/credit-note/credit-note.component";
import { DebitNoteComponent } from './transaction-dash/debit-note/debit-note.component';
import { SalesPosComponent } from "./transaction-dash/sales/sales-pos/sales-pos.component";
import { InvoiceDuePaymentComponent } from './transaction-dash/sales/invoice-due-payment/invoice-due-payment.component';
import { JournalDashComponent } from "./transaction-dash/vouchers/journal-dash/journal-dash.component";
import { ExpenseTaxJournalComponent } from "./transaction-dash/vouchers/expense-tax-journal/expense-tax-journal.component";
import { SalesPosResComponent } from "./transaction-dash/sales/quick-sale/sales-pos-res/sales-pos-res.component";
const routes: Routes = [
  { path: "branch_stock_receipt", component: BranchStockReceiptComponent },
  {
    path: "branch_stock_transfer",
    component: InterBranchStockTransferComponent,
  },
  {
    path: "branch_transfer_receipt",
    component: BranchTransferReceiptComponent,
  },
  { path: "transaction", component: TransactionDashComponent },
  { path: "vouchers", component: VouchersComponent },
  { path: "receipt", component: ReceiptComponent },
  { path: "payment", component: PaymentComponent },
  { path: "cheque", component: ChequeComponent },
  { path: "credit-note", component: CreditNoteComponent },
  { path: "receipt_dashboard", component: ReceiptDashComponent },
  { path: "payment_dashboard", component: PaymentDashComponent },
  { path: "journal_dashboard", component: JournalDashComponent },

  { path: "journal", component: JournalComponent },
  { path: "expense-tax-journal", component: ExpenseTaxJournalComponent },
  { path: "contra", component: ContraComponent },
  { path: "sales", component: SalesComponent },
  { path: "sales_rtn_without", component: SalesRetWithoutInvComponent },
  { path: "invoice-due-receipt", component: InvoiceDueReceiptComponent },
  { path: "sales_void", component: SalesVoidComponent },
  { path: "sales_return_void", component: SalesReturnVoidComponent },
  { path: "sales_return", component: SalesReturnComponent },
  { path: "inter_branch_receipt", component: InterBranchReceiptComponent },
  { path: "inter_branch_payment", component: InterBranchPaymentComponent },
  { path: "sales_form", component: SalesFormComponent },
  { path: "sales-quoatation", component: SalesQuoatationComponent },
  { path: "sales-invoice", component: SalesInvoiceComponent },
  { path: "delivery-challan", component: DeliveryChallanComponent },
  { path: "quick-sales", component: QuickSaleComponent },
  { path: "expense-tax-payment", component: ExpenseTaxPaymentComponent },
  { path: "ext-branch-transfer", component: ExtStockTransferComponent },
  { path: "ext-branch-receipt", component: ExtStockReceiptComponent },
  { path: 'sales-invoice/:qtn_id', component: SalesInvoiceComponent},

  { path: 'delivery-challan/sales-invoice/:del_chellan_inv_no', component: SalesInvoiceComponent},
  { path: 'sales-order/sales-invoice/:sale_order_inv_no', component: SalesOrderComponent},
  { path: 'delivery-challan/:qtn_id', component: DeliveryChallanComponent},
  { path: 'sales-order/:qtn_id', component: SalesOrderComponent},
  { path: 'sales-invoice/delivery-challan/:sales_inv_no', component: DeliveryChallanComponent},
  // { path: 'quick-sales/:qtn_id', component: QuickSaleComponent},
  { path: "sales-order", component: SalesOrderComponent },
  { path: "sales-order/quick-sales/:sales_odr_inv_no", component: QuickSaleComponent },
  { path: "sales-order/delivery-challan/:sales_odr_inv_no", component: DeliveryChallanComponent },
   { path: "quick-sales/:ord_id", component: QuickSaleComponent },
   { path: "debit_note", component: DebitNoteComponent },
   { path: "sales-pos", component: SalesPosComponent},
   { path: "sales-pos-res", component: SalesPosResComponent},
   { path: "invoice-due-payment", component: InvoiceDuePaymentComponent },
];

@NgModule({
  imports: [FormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionRoutingModule {}
