import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/service/api.service';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-sales-pos-res',
  templateUrl: './sales-pos-res.component.html',
  styleUrls: ['./sales-pos-res.component.css']
})
export class SalesPosResComponent implements OnInit {
  payPageClick: boolean = false;
  editBtns: boolean = false;
  prod_alias: any;
  base_url: string;
  gd_id: string | Blob;
  productDetails: any;
  eInvoiceSupport: any;
  customer: any;
  saleqt_date = <any>new Date();
  
  selectedProduct = <any>[
    // {
    //   salesub_prd: <any>"",
    //   salesub_qty: null,
    //   prd_code: null,
    //   base_qty: null,
    //   salesub_rate: null,
    //   salesub_unit_id: "",
    //   salesub_tax_per: null,
    //   prd_tax_cat_id: null,
    //   salesub_tax: "",
    //   item_desc: "",
    //   salesub_gd_id: "",
    //   prod_units: [],
    //   unit: "",
    //   // qty: null,
    //   loadingImg: false,
    //   isSecondOptionDisabled: true,
    //   rate2_dsply_qty: 0,
    //   rate: null,
    //   disc_amnt: 0,
    //   item_disc_type: 2,
    //   excl_vat: 1,
    //   taxpercentage: null,
    //   itemRate: null,
    //   grnd_totl: null,
    //   base_unit_rate: null,
    //   item_disc: 0,
    //   item_bill_disc: 0,
    //   barcode: <any>"",
    //   stock_qty: 0,
    //   salesub_tax_amnt: 0,
    //   sel_rate_type: 1,
    // },
  ];
  country_dec: number;
  saleqt_time: any;
  user_name: any;
  edit_note: any;
  editIndex: any;
  custype: any;
  cust_vat: any;
  cust_mob: any;
  cust_addres: any;
  totItemprice= <any>0;
  totVatAmnt=<any>0;
  totItemqty=<any>0;
  saleRefNo: any;
  qt_no: any;
  cashLedgers: any;
  bankLedgers: any;
  sale_pay_type: any;

  constructor(private translate: TranslateService,private apiService: ApiService, private coreService: CoreService,private modalService: NgbModal,public router: Router,private ActiveRoute: ActivatedRoute,private datePipe: DatePipe) { }

  ngOnInit() {
    this.base_url =this.apiService.PHP_API_SERVER;

    this.searchStkPrd('');
    this.searchCustomer('');
    this.country_dec = this.coreService.setDecimalLength();
    this.saleqt_time = this.saleqt_date.toLocaleTimeString();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.langChange();
    });
    this.user_name=this.coreService.getUserData('usr_name');
    this.nextRefNo();
    this.searchBankLedger('');
    this.searchCashLedger('');
  }

   // do not use this api other than sales bcz add some exeption in this (by ajmal)
   searchStkPrd(search: string) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append("stk_stat", "1");
    searchval.append("godown_id", this.gd_id);
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getSalesStockbyItem(searchval).subscribe((res) => {
      this.productDetails = res["data"];
      
      this.countUpdate();
    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append("cust_name", search);
    if(this.eInvoiceSupport){
      searchval.append('einvoice_details', '1');
    }
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res["data"];
    });
  }
  selectCustomer(cust_id){
    if(cust_id){
      this.cust_vat=cust_id.vat_no;
      this.cust_mob=cust_id.mobile;
      this.cust_addres=cust_id.cust_home_addr;
    }else{
      this.cust_vat='';
      this.cust_mob='';
      this.cust_addres='';
    }

  }

  // searchStkPrdBarcode(search: any) {
  //   if (!search) {
  //     $("#br_err").text("Enter Barcode");
  //     return false;
  //   } else {
  //     $("#br_err").text("");
  //   }
  //   let searchval = new FormData();
  //   searchval.append("barcode", search);
  //   this.prodUnitLoading = true;
  //   this.apiService.getStockbyBarcodeSales(searchval).subscribe((res) => {
  //     // this.stkprd_all = res['data'];
  //     if (res.data) {

  //       $("#br_err").text("");
  //       this.openPopUp(res.data);
  //       this.stkprdsel = res.data;
  //       this.selectedProduct(res.data.prd_id, this.gd_id, this.stkprdsel, true);
  //       this.selectQty.nativeElement.focus();
  //       this.selectQty.nativeElement.select();

  //     } else {
  //       $("#br_err").text("No product found");
  //       this.stkprdsel = {
  //         prd_name: null
  //       };
  //       this.stkprdsel = "";
  //       this.selctedProd = {};
  //       this.qty = '';
  //       this.rate = '';
  //       this.rate_org = '';
  //       this.unit = '';
  //       this.disc_amnt = 0;
  //       // this.prd_tax_cat_id = '';
  //       this.tax_amnt = '';
  //       this.grnd_totl = '';
  //       this.item_dicrptn = '';
  //       this.godownStocks = [];
  //       this.branchStocks = [];
  //       this.lastInvoice = [];
  //       this.isEdit = false;
  //       this.sl_no = '';
  //       this.base_qty = '';
  //       this.unit_name = '';
  //       this.promo_disc = 0;
  //       this.promo_per = 0;
  //     }

  //   });
  // }
  nextRefNo() {
    let searchval = new FormData();

    this.apiService.getSaleRefNo(searchval).subscribe((res) => {
      this.saleRefNo = res['data'].sale_num;
      this.qt_no = res['data'].sq_branch_qt_no;

    });
  }


  selectProd(data) {
   
    
 
      if ((this.selectedProduct.length > 0)&&(this.selectedProduct.find(x => x.salesub_prd_id === data.prd_id))) {
        this.selectedProduct.find(x => x.salesub_prd_id === data.prd_id).salesub_qty++;
      }else{
        this.selectedProduct.push({
          "salesub_prd": data.prd_name,"salesub_prd_id": data.prd_id, "salesub_rate": data.bs_srate,"salesub_tax": ((data.bs_srate/100)*data.prd_tax), "salesub_qty": 1, "count": 1,"salesub_note":'',
        });
      }
     
      this.countUpdate();
  }

  countUpdate(){
    this.selectedProduct.forEach((element,i) => {
      this.productDetails.find(x => x.prd_id === element.salesub_prd_id).count=element.salesub_qty;
     });
     this.sumSaleTotal();
  }

  delete(index) {
    this.productDetails.find(x => x.prd_id === this.selectedProduct[index].salesub_prd_id).count=0;
    this.selectedProduct.splice(index, 1);
    
  }

  payPage() {
    this.payPageClick = true;
  }

  back() {
    this.payPageClick = false;
  }

  editItem(index) {
    this.editBtns = true;
    this.editIndex=index;
    this.edit_note=this.selectedProduct[index].salesub_note;

  }

  addnote(e:string){
    this.selectedProduct[this.editIndex].salesub_note=e;
  }
  changeQty(value){
    if(value){
      this.selectedProduct[this.editIndex].salesub_qty++;
    }else{
      if(this.selectedProduct[this.editIndex].salesub_qty>0){
        this.selectedProduct[this.editIndex].salesub_qty--;
      }
    }
    this.countUpdate();
    
  }
  inputQty(value){
    if(this.selectedProduct){
    let exist_qty=this.selectedProduct[this.editIndex].salesub_qty>0?this.selectedProduct[this.editIndex].salesub_qty:'';
  
      this.selectedProduct[this.editIndex].salesub_qty=exist_qty.toString()+value.toString();
      this.countUpdate();
    }
   
  }
  clearQty(){
    this.selectedProduct[this.editIndex].salesub_qty=0;
    this.countUpdate();
  }
  backspace(){
   
    let exist_qty=this.selectedProduct[this.editIndex].salesub_qty>0?this.selectedProduct[this.editIndex].salesub_qty.toString():0;
    
    if(exist_qty.length>0){
      this.selectedProduct[this.editIndex].salesub_qty=exist_qty.toString().slice(0, -1);
    }
    this.countUpdate();
    
  }
  decimalQty(){
    let exist_qty=this.selectedProduct[this.editIndex].salesub_qty>0?this.selectedProduct[this.editIndex].salesub_qty.toString():0;
    this.selectedProduct[this.editIndex].salesub_qty= exist_qty+= '.';

  }
  changeSubQty(){
    this.countUpdate();
    
  }
  changecustype(){
    console.log(this.custype+'this.custype');
    if(!this.custype){
      this.cust_vat='';
      this.cust_mob='';
      this.cust_addres='';
    }
    
  }
  sumSaleTotal() {
    this.totItemprice = 0;
    this.totItemqty= 0;
    // this.totItempriceWithoutBillDisc = 0;
    // this.totItemDisc = 0;
    // this.totItemDiscWithoutBillDisc = 0;
    this.totVatAmnt = 0;
    // this.totVatAmntWithoutBillDisc = 0;
    // this.billDiscountTtl = 0;

    for (var i = 0; i < this.selectedProduct.length; i++) {
     
      this.totItemqty =
        Number(this.totItemqty) +
        Number(this.selectedProduct[i]["salesub_qty"]);
      this.totItemprice =
        Number(this.totItemprice) +
        Number(this.selectedProduct[i]["salesub_qty"]) *
          Number(this.selectedProduct[i]["salesub_rate"]);
      // this.totItempriceWithoutBillDisc =
      //   Number(this.totItempriceWithoutBillDisc) +
      //   Number(this.selectedProduct[i]["salesub_qty"]) *
      //     Number(this.selectedProduct[i]["salesub_rate_without_bill_disc"]);
      // this.totItemDisc =
      //   Number(this.totItemDisc) + Number(this.selectedProduct[i]["item_disc"]);
      // this.totItemDiscWithoutBillDisc =
      //   Number(this.totItemDiscWithoutBillDisc) +
      //   Number(this.selectedProduct[i]["item_disc_WithoutBillDisc"]);
      this.totVatAmnt =
        Number(this.totVatAmnt) +
        Number(this.selectedProduct[i]["salesub_tax"]);
      // this.totVatAmntWithoutBillDisc =
      //   Number(this.totVatAmntWithoutBillDisc) +
      //   Number(this.salesItems[i]["salesub_tax_amnt_WithoutBillDisc"]);
    }
    // if(this.sale_pay_type==4){
    //   this.changePay(4);
    // }
  }

  searchCashLedger(search: string) {
    const searchval = new FormData();
    searchval.append("ledger_name", search);
    searchval.append("ledger_grp", "3");

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.cashLedgers = res["data"];
    });
  }
  searchBankLedger(search: string) {
    const searchval = new FormData();
    searchval.append("ledger_name", search);
    searchval.append("ledger_grp", "4");
    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.bankLedgers = res["data"];
    });
  }
  payType(value){
    this.sale_pay_type=value;
  }

}
