import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barcode-settings',
  templateUrl: './barcode-settings.component.html',
  styleUrls: ['./barcode-settings.component.css']
})
export class BarcodeSettingsComponent implements OnInit {

  Show:boolean=true;

  visible:boolean=false

  showInput(){
    this.Show=!this.Show
    this.visible=!this.visible
  }

  constructor() { }

  ngOnInit() {
  }

}
