import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';

import { Van } from '../../../../model/van/van.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-van',
  templateUrl: './van.component.html',
  styleUrls: ['./van.component.css']
})

export class VanComponent implements OnInit {
  catagories: any;
  van_cat_update: any;
  all_cat= 0;
  from: any;
  products: any;
 
  van_prods_update: any;
  van_fav_prods_update:any;
  prod_alias: string;
  allCategory= 0;
  van_cat: any;
  van_prods: any[];
  van_fav_prods: any[];
  resultobj1= false;
  editData =  false;


  constructor(private apiService: ApiService,private coreService: CoreService, private translate: TranslateService) { }
  product_summary: any;
  // van_type: any;
  elseBlocknostart: any;
  elseBlocknoend: any;
  van_password: any;
  selectVat: any;

  van_id:number;
  van_vanline_id:String;
  van_name:String;
  van_code:String;
  van_contact_person:String;
  van_reg_no:String;
  vanline_id:number;
  ledger_id:number;
  van_ledger_id:number;
  van_contact_phone:any;
  van_contact_mob:String;
  van_contact_email:String;
  van_contact_address:String;
  van_desc:String;
  van_type:String;
  vanlist:any[];
  curpage:number;
  lastpage:number;
  changeBox :boolean;
  pgstart:boolean;
  pgend:boolean;
  slnum:number;
  ledger_all: any;
  vanline_all: any;
  resultobj: any = {};
  prd_stock_stat = 1;
  prd_stock_statusUnchecked = false;
  prd_stock_statusChecked = true;
  prd_stock_status = true;

  userAll: any = [];
  isSubmitInProg = false;
  isEditSubmitInProg = false;
  usrIds = [];
  editUsrIds: any = [];
  oldUsrIds: any = [];
  bank_ledger_all: any;
  bank_ledger_id:any;
  cur_lang: string;
  // allProviders: any[] = [{ PROV: "aaa" }, { PROV: "aab" }, { PROV: "aac" }];
  fav_products =[{ prd_id: 0, prd_name:"" }];
  filteredFavProd: any[] =this.fav_products;

  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('closeModal1', { static: false }) closeModal1: ElementRef;
  van_op_bal = 0;
  ngOnInit() {

    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })
    this.prd_stock_stat = 1;
    this.readVan();
    this.getLedgers();
    this.getAllBankLedgers();
    this.getVanlines();
    this.searchCat();

  }
  
aliasChecked(){
  if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
    this.prod_alias = 'alias_true';
    
  }else{
    this.prod_alias =null;
    
  }
}
  getAllBankLedgers() {
    this.apiService.getAllBankLedgers().subscribe((res) => {
      this.bank_ledger_all = res['data'];
    });
  }
  readVan()
 {
  let searchval = new FormData();   
 this.apiService.ListVan(searchval,1).subscribe((res) => {
  this.vanlist= res['data']['data'];
  this.curpage = res['data']['current_page'];  
  this.lastpage= res['data']['last_page'];
  this.from= res['data']['from']; 

  this.pgEnd(this.curpage,this.lastpage); 
  this.pgStart(this.curpage);
  this.slnum=0;
});
}


//form search
searchCat()
{
 let searchval = new FormData();      
      
       this.apiService.readCat().subscribe((res) => {
        this.catagories = res['cat'];  
        // console.log(this.catagories);              
       });
     
}

searchProdByCats(ids) {
  this.van_prods =[];
  this.products =[];
  const searchval = new FormData();
  searchval.append('prod_cats', ids);
  this.apiService.getProdByCat(searchval).subscribe((res) => {
    this.products = res['data'];

    console.log("products11");
    console.log(this.products);
    
    const tmp = [];
    this.products.forEach((element) => {
      tmp.push(element.prd_id);
    });
    this.van_prods = tmp;

    console.log("products22");
    console.log(this.products);
    this.aaaaaaaaa(this.van_prods);

    if(!this.editData){
      this.fav_products = res['data'];
      
    }
    
  });

}

pageNext(formdata: { value: any; }, pageno:any)
{

let searchval = new FormData();   
this.apiService.ListVan(searchval,pageno).subscribe((res) => {
 this.vanlist = res['data']['data'];
 this.curpage = res['data']['current_page'];  
 this.lastpage= res['data']['last_page']; 
 this.from= res['data']['from']; 

 this.pgEnd(this.curpage,this.lastpage); 
 this.pgStart(this.curpage);
 this.slnum=0;


});
}


addNewVan(formgroup: { value: any; }) {
  this.isSubmitInProg = true;
  this.apiService.addNewVan(formgroup.value).subscribe((res: Van) => {
    this.isSubmitInProg = false;
    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
      this.resultobj1 =true;
    } else {
      this.closeModal.nativeElement.click();
      this.readVan();
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      formgroup.value.reset();
      this.resultobj = null;
      res.error = null;
      this.resultobj1 =false;
      this.clearForm();
    }

  });
}


updateVan(formgroup: { value: any; }) {

  this.isEditSubmitInProg = true;
  this.apiService.updateVan(formgroup.value).subscribe((res: Van) => {
    this.isEditSubmitInProg = false;
    if (res.error != null) {
      this.resultobj = res.error;
      this.resultobj = this.coreService.getValidation(this.resultobj);
      this.resultobj1 =true;
    } else {
      this.closeModal1.nativeElement.click();
      this.readVan();
      this.coreService.createfunct(formgroup, this.resultobj, res.message);
      this.resultobj = {};
      this.resultobj1 =false;
      this.clearForm();
      this.editData =false;
    }

  });
}

setValues(data)
{
  
this.editData =true;
this.resultobj1 =false;
  console.log("dataaaaaaaaaaaaaaaa");
  console.log(data);
this.van_id =data.van_id;
this.van_vanline_id =data.van_vanline_id;
this.van_code =data.van_code;
this.van_name =data.van_name;
this.van_contact_person =data.van_contact_person;
this.van_reg_no =data.van_reg_no;
this.van_contact_mob =data.van_contact_mob;
this.van_contact_email =data.van_contact_email;
this.van_contact_address =data.van_contact_address;
this.van_desc =data.van_desc;
this.van_type =data.van_type;
this.all_cat = data.all_cat;


this.van_cat_update = data.van_catgory.map(function(a) {return a.vc_cat_id;});
 this.van_prods_update = data.van_products.map(function(a) {return a.vp_prd_id;});
this.van_fav_prods_update = data.van_fav_products.map(function(a) {return a.vp_prd_id;});
if(this.all_cat == 1){

  this.allCategory = 1;
  this.allClick(this.all_cat);
   //$("#supchkEnable").click();


}
else{
  this.allCategory = 0;
}

console.log("jjjjj");
console.log(this.all_cat);

console.log(this.allCategory);

this.aaaaaaaaa(this.van_prods_update);
//this.van_prods_update = data.van_products.map(function(a) {return a.vp_prd_id;});

this.van_vanline_id =data.vanlines.vanline_id;
this.van_ledger_id =(data.ledger)?data.ledger.ledger_id:null;
this.van_contact_phone =data.van_contact_phone;
this.userAll = data.vanusers;
this.van_op_bal = data.van_op_bal;
this.bank_ledger_id = data.bank_ledger_id;
const tmp = [];
data.vanusers.forEach((element) => {
  tmp.push(element.usr_id);
});
this.editUsrIds = tmp;
this.oldUsrIds = tmp;
this.searchProdByCats(this.van_cat_update);

console.log('this.userAll');
console.log(this.userAll);

}
searchLedger(search: string) {
  let searchval = new FormData();
  searchval.append("ledger_name", search);
  this.apiService.getLedByName(searchval).subscribe((res) => {
    this.ledger_all = res['data'];
  
  });
}

searchUser(search: string) {
  let searchval = new FormData();
  searchval.append("usr_name", search);
  searchval.append("usr_type", '4');
  this.apiService.getUserByName(searchval).subscribe((res) => {
    this.userAll = res['data'];
   
  });
}


searchVanlines(search: string) {
  let searchval = new FormData();
  searchval.append("vanline_name", search);
  this.apiService.getVanlinesName(searchval).subscribe((res) => {
    this.vanline_all = res['data'];
 
  });
}

activate(vanlineId) {
  this.apiService.changevanStatus(vanlineId).subscribe((res: Van) => {
    this.coreService.showMessage(res.message);
  });
}



pgEnd(curr,end)
{
if(curr==end)
this.pgend = true;
else
this.pgend = false;

}

pgStart(curr)
{
if(curr==1)
this.pgstart = true;
else
this.pgstart = false;
}


getLedgers() {
  let searchval = new FormData();  
  this.apiService.getLedgers(searchval).subscribe((res) => {
    this.ledger_all = res['data'];   
  });
}

getVanlines() {
  let searchval = new FormData();  
  this.apiService.getVanlines(searchval).subscribe((res) => {
    this.vanline_all = res['data'];     
  });
}
aaaaaaaaa(van_prods){
  console.log("jjjjjjjjjjjjjjjjjjjjjj");
  console.log(van_prods);
if(this.allCategory == 0){
  const searchval = new FormData();
  
   searchval.append('category_check', '0');
  searchval.append('prod_ids', van_prods);
  this.apiService.getProdByIds(searchval).subscribe((res) => {
    this.fav_products = res['data'];
    console.log("Fav Products");
    console.log(this.fav_products);

  });
}
}

searchProduct(text,ids){
  console.log("text");
  console.log(text);

  console.log("ids");
  console.log(ids);

}
allClick(all_check){

  console.log("iiiiiiiiiiiiiii");
  console.log(all_check);
  this.allCategory = all_check;

   if(this.allCategory == 1){
    console.log("true");
    const searchval = new FormData();
    searchval.append('category_check', '1');
    searchval.append('prod_ids', '0');
    this.apiService.getProdByIds(searchval).subscribe((res) => {
      this.fav_products = res['data'];
      console.log("Fav Products");
      console.log(this.fav_products);

      console.log("edit Fav Prdo");
      console.log(this.van_prods_update);
  
    });
   }
  //  else{
  //   console.log("false");
  //  }

}

filterTypes = [
  { id: 1, name: 'Company' },
  { id: 2, name: 'Other' },
];

onInputChange(event: any) {
  const searchInput = event.target.value.toLowerCase();

  // this.filteredProviders = this.allProviders.filter(({ PROV }) => {
  this.filteredFavProd = this.fav_products.filter(({ prd_name }) => {
    const prod = prd_name.toLowerCase();
    return prod.includes(searchInput);
  });
}

onOpenChange(searchInput: any) {
  searchInput.value = "";
  // this.filteredProviders = this.allProviders;
  this.filteredFavProd = this.fav_products;
}

addNew(){
  this.clearForm();
}


clearForm() {
  this.van_name ="";
  this.van_code = "";
  this.van_reg_no = "";
  this.van_type ="";
  this.all_cat =0;
  this.van_cat ="";
  this.prod_alias ="";
  this.van_prods =[];
  this.van_fav_prods =[];
  this.van_ledger_id =0;
  this.bank_ledger_id = 0;
  this.van_vanline_id ="";
  this.usrIds =[];
  this.van_password ="";
  this.van_contact_person = "";
  this.van_contact_phone ="";
  this.van_contact_mob ="";
  this.van_contact_email ="";
  this.van_contact_address ="";
  this.van_desc = "";
  this.van_op_bal =0;
  this.resultobj1 =false;
  this.allCategory =0;
}

}
