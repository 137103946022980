import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyComponent } from './company/company.component';
import { BarcodeComponent } from './barcode/barcode.component';
// component

import { UserPrivilagesComponent } from './user-privilages/user-privilages.component';
import { LedgerPrivilagesComponent } from './ledger-privilages/ledger-privilages.component';
import { PrintComponent } from './print/print.component';
import { AdvanceSettingsComponent } from './advance-settings/advance-settings.component';
import { RemoveUnusedProductsComponent } from './advance-settings/remove-unused-products/remove-unused-products.component';
import { PurchaseSettingsComponent } from './purchase-settings/purchase-settings.component';
import { SalesSettingsComponent } from './sales-settings/sales-settings.component';
import { SalesQtnSettingsComponent } from './sales-qtn-settings/sales-qtn-settings.component';
import { InterBranchTransferSettingsComponent } from './inter-branch-transfer-settings/inter-branch-transfer-settings.component';
import { Template1Component } from './template1/template1.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { DasboardSettingsComponent } from './dasboard-settings/dasboard-settings.component';
import { EnableDisableModuleComponent } from './enable-disable-module/enable-disable-module.component';
import { PriceCheckerSettingsComponent } from './price-checker-settings/price-checker-settings.component';
import { NotificationComponent } from './advance-settings/notification/notification.component';
import { GodownSettingsComponent } from './godown-settings/godown-settings.component';
import { ProductSettingsComponent } from './product-settings/product-settings.component';
import { DeliveryChallanSettingsComponent } from './delivery-challan-settings/delivery-challan-settings.component';
import { VanSettingsComponent } from './van-settings/van-settings.component';
import { CurrencyMasterComponent } from './currency-master/currency-master.component';
import { MailSettingsComponent } from './mail-settings/mail-settings.component';
import { RemoveInactiveLedgerComponent } from './advance-settings/remove-inactive-ledger/remove-inactive-ledger.component';
import { SalesOrderSettingsComponent } from './sales-order-settings/sales-order-settings.component';
import { VoucherSettingsComponent } from './voucher-settings/voucher-settings.component';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { ExportAllproductsComponent } from './advance-settings/export-allproducts/export-allproducts.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { ExcelConvertComponent } from './advance-settings/excel-convert/excel-convert.component';
import { DatabaseClearanceComponent } from './advance-settings/database-clearance/database-clearance.component';
import { BarcodeSettingsComponent } from './barcode-settings/barcode-settings.component';
import { RateEditLogComponent } from './advance-settings/rate-edit-log/rate-edit-log.component';
import { DatabaseBackupComponent } from './database-backup/database-backup.component';
import { DatabaseClosingComponent } from './database-closing/database-closing.component';
import { PrefixSettingsComponent } from './prefix-settings/prefix-settings.component';
const routes: Routes = [
  {path: 'company_settings', component: CompanyComponent},
  {path: 'barcode-printing', component: BarcodeComponent},
  {path: 'privilages', component: UserPrivilagesComponent},
  {path: 'ledger-privilages', component: LedgerPrivilagesComponent},
  {path: 'print', component: PrintComponent},
  {path: 'advanced-settings/dashboard', component: AdvanceSettingsComponent},
  {path: 'advanced-settings/un-used-products', component: RemoveUnusedProductsComponent},
  {path: 'purchase-settings', component: PurchaseSettingsComponent},
  {path: 'sales-settings', component: SalesSettingsComponent},
  {path: 'sales-qtn-settings', component: SalesQtnSettingsComponent},
  {path: 'delivery-challan-settings', component: DeliveryChallanSettingsComponent},
  {path: 'inter-branch-transfer-settings', component: InterBranchTransferSettingsComponent},
  {path: 'template-1', component: Template1Component},
  {path: 'client-settings', component: ClientSettingsComponent},
  {path: 'dashboard-settings', component: DasboardSettingsComponent},
  {path: 'enable-disable-module', component: EnableDisableModuleComponent},
  {path: 'price-checker-settings', component: PriceCheckerSettingsComponent},
  {path: 'advanced-settings/notification', component: NotificationComponent},
  {path: 'advanced-settings/export-allproducts', component: ExportAllproductsComponent},
  {path: 'advanced-settings/inactive-ledger', component: RemoveInactiveLedgerComponent},
  {path: 'godown-settings', component: GodownSettingsComponent},
  {path: 'product-settings', component: ProductSettingsComponent},
  {path: 'van-settings', component: VanSettingsComponent},
  {path: 'currency-master', component: CurrencyMasterComponent},
  {path: 'barcode-settings', component: BarcodeSettingsComponent},
  {path: 'mail-settings', component: MailSettingsComponent},
  {path: 'sales-order-settings', component: SalesOrderSettingsComponent},
  {path: 'voucher-settings', component: VoucherSettingsComponent},
  {path: 'language-settings', component: LanguageSettingsComponent},
  {path: 'general-settings', component: GeneralSettingsComponent},
  {path: 'advanced-settings/excel-conversion', component: ExcelConvertComponent},
  {path:'advanced-settings/database-clearance',component:DatabaseClearanceComponent},
  {path:'advanced-settings/rate-edit-log',component:RateEditLogComponent},
  {path:'advanced-settings/prefix-settings',component:PrefixSettingsComponent},
  {path:'database-backup',component:DatabaseBackupComponent},
  {path:'database-closing',component:DatabaseClosingComponent},
 
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SettingsRoutingModule {}