import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { CoreService } from '../../../service/core.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-prefix-settings',
  templateUrl: './prefix-settings.component.html',
  styleUrls: ['./prefix-settings.component.css']
})
export class PrefixSettingsComponent implements OnInit {
  prefix_list: any;
  branch_code = '';
  maxPayNo='';
  newNo:any;
  is_update: any;
  valErrors = <any>{};

  constructor(private apiService: ApiService, private coreService: CoreService,private translate: TranslateService) { }

  ngOnInit() {
   this.getPrefixSetting()
   this.branch_code = this.coreService.getUserData('branch_code');
   console.log(' this.branch_code', this.branch_code);
   
   this.generateBranchPayNo();
 console.log(this.newNo);
    let newNo=37;
 console.log('suii');
 
   console.log(`W#P${this.branch_code}${newNo.toString().padStart(6, '0')}`);
   
   
  }
  generateBranchPayNo() {
    if (!this.is_update) {
      return `W#P${this.branch_code}000001`;
    } else {
      // Extract the numeric part by removing the prefix "W#P" + branchCode
      const numPart = this.is_update.replace(`W#P${this.branch_code}`, '');
      const newNo = parseInt(numPart, 10) + 1;
      console.log(newNo);
      
      // Pad the number to 6 digits
      return `W#P${this.branch_code}${newNo.toString().padStart(6, '0')}`;
    }
  }

  getPrefixSetting()
  {
    // this.disc_pageLoadingImg = true;
    this.apiService.getPrefixSetting().subscribe((res) => {
      this.prefix_list = res['data'];

       
        this.prefix_list.forEach((item, index) => {
          let prefix='';
          let newNo=item.last_invoice;
         
          if (item.is_type == 'sales'){
             prefix= `W#${this.branch_code}${newNo.toString().padStart(6, '0')}`
          }else if(item.is_type=='sales_order'){
             prefix= `SLO#${this.branch_code}${newNo.toString().padStart(6, '0')}`
          }else if(item.is_type=='sales_quotation'){
            prefix= `${this.branch_code}/QT${newNo.toString().padStart(5, '0')}`
          }else if(item.is_type=='delivery_note'){
            prefix= `${this.branch_code}/DC${newNo.toString().padStart(6, '0')}`
          }
            this.prefix_list[index].current_prefix=prefix;
            this.prefix_list[index].updated_prefix = prefix;

            
        
        
        });
    });
  } 
  updatePrefix(index: number) {
    let item = this.prefix_list[index];
    let newNo = item.is_update || item.is_start_from; // Use is_update if available, else fallback
  
    if (item.is_type == 'sales') {
      item.updated_prefix = `W#${this.branch_code}${newNo.toString().padStart(6, '0')}`;
    } else if (item.is_type == 'sales_order') {
      item.updated_prefix = `SLO#${this.branch_code}${newNo.toString().padStart(6, '0')}`;
    } else if (item.is_type == 'sales_quotation') {
      item.updated_prefix = `${this.branch_code}/QT${newNo.toString().padStart(5, '0')}`;
    } else if (item.is_type == 'delivery_note') {
      item.updated_prefix = `${this.branch_code}/DC${newNo.toString().padStart(6, '0')}`;
    }
  }
  update(formdata) {
    // this.pageLoadingImg = true;
    formdata.value.aa = 'value2';
    formdata.value.prefixes = JSON.stringify(this.prefix_list);
    if (this.vaidateForm()) {
      this.apiService.prefSetting(formdata.value).subscribe((res) => {
        // this.pageLoadingImg = false;
        console.log('res',res);
        
        if (res.data) {
          this.coreService.showMessage('Setting Saved');
        }
      });
    }
   
  }
  vaidateForm() {
    this.valErrors = {};
    let retVal = true;
    // for (let i = 0; i < this.prefix_list.length; i++) {
    //   const current = Number(this.prefix_list[i].current_prefix);
    //   const updated = Number(this.prefix_list[i].is_update);
  
    //   if ( updated < current) {
    //     this.valErrors[i] = 'Updated prefix cannot be less than current prefix.';
    //     retVal = false;
    //   }
    // }

    this.prefix_list.forEach((element, i) => {
console.log('element.is_update',element.is_update,'  ',element.last_invoice);
this.valErrors[i] = '';
      if (element.is_update <= element.last_invoice && element.is_update) {
        console.log('siuuu',element.is_update,'  ',element.last_invoice);
        this.valErrors[i] = 'value must be greater than current prefix.';
        retVal = false;
      }
    });
    return retVal;
  }
}
